import styles from "./BrowsePage.module.css";
import Featured from "../components/Featured";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";
import classnames from "classnames";

export default function BrowsePage({ featuredStory, storySections }) {
	return (
		<div className={styles.page}>
			<Header />
			<Featured story={featuredStory} />
			<Stories sections={storySections} />
			<Footer />
		</div>
	);
}

function Stories({ sections }) {
	return (
		<div className={styles.stories}>
			{sections.map((section, i) => (
				<Section
					key={i}
					title={section.title}
					stories={section.stories}
				/>
			))}
		</div>
	);
}

function Section({ title, stories }) {
	return (
		<div className={styles.section}>
			<h3 className={styles.section__title}>{title}</h3>
			<ul className={styles.section__stories}>
				{stories.map((story) => (
					<Tile key={story.id} story={story} />
				))}
			</ul>
		</div>
	);
}

function Tile({ story }) {
	const { id, title, thumbnail, poster, pilot } = story;

	return (
		<li className={styles.tile}>
			<div className={styles.tile__box}>
				<Link to={`/story/${id}`} className={styles.tile__contents}>
					<img
						className={styles.tile__poster}
						alt={title}
						src={thumbnail || poster}
					/>
				</Link>
				{pilot ? <TileRibbon text="Pilot" color="purple" /> : null}
			</div>
		</li>
	);
}

const TileRibbon = ({ text, color }) => (
	<div className={styles.tile__ribbon}>
		<div className={classnames(styles.tile__ribbon__label, styles[color])}>
			{text}
		</div>
	</div>
);
