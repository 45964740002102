export function centeredPopup({ url, title, w, h, options }) {
    // Source: https://stackoverflow.com/a/16861050/1795244

    // Fixes dual-screen position
    const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;

    // const systemZoom = 1;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
        url,
        title,
        `
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      ${options || ""}
      `
    );

    if (window.focus) newWindow.focus();
}
