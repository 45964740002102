import styles from "./Featured.module.css";
import { StoryPlayButton } from "./common/StoryStartButtons";

export default function Featured({ story }) {
	return (
		<div className={styles.featured}>
			<div className={styles.bg}>
				<Background story={story} />
			</div>
			<div className={styles.info}>
				<h2 className={styles.title}>
					<img
						className={styles.title__image}
						src={story.logo}
						alt={story.title}
					/>
				</h2>
				<p className={styles.description}>{story.description}</p>
				<StoryPlayButton story={story} featured />
			</div>
		</div>
	);
}

function Background({ story }) {
	const { featuredBackground, posterNoLogo, title } = story;

	// TODO: video?

	const src = featuredBackground || posterNoLogo;
	if (src) {
		return <img src={src} className={styles.bg__image} alt={title} />;
	}

	return null;
}
