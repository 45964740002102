import styles from "./TextField.module.css";
import classnames from "classnames";
import { useState, useCallback, forwardRef } from "react";

const TextField = forwardRef((props, ref) => {
	const {
		dataKey,
		id,
		type,
		label,
		className,
		tabIndex,
		autoComplete,
		onChange,
	} = props;
	const [focused, setFocused] = useState(false);
	const [value, setValue] = useState("");

	const changeHandler = useCallback(
		(evt) => {
			setValue(evt.target.value);
			onChange && onChange(dataKey, evt.target.value);
		},
		[onChange, dataKey]
	);

	return (
		<div
			className={classnames(styles.field, className, {
				focused: focused || value !== "",
			})}
		>
			<label className={styles.field__label} htmlFor={id}>
				{label}
			</label>
			<input
				ref={ref}
				className={styles.field__input}
				id={id}
				tabIndex={tabIndex}
				type={type || "text"}
				autoComplete={autoComplete}
				onChange={changeHandler}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
		</div>
	);
});

export default TextField;
