import styles from "./WidgetPage.module.css";
import WidgetContents from "../components/widget/WidgetContents";
import useBodyClass from "../utils/useBodyClass";

import { Loader as IconLoader } from "lucide-react";
import { useEffect, useState } from "react";
import classnames from "classnames";

export default function WidgetPage() {
	useBodyClass("light");
	const [loading, setLoading] = useState(true);
	const [config, setConfig] = useState(null);
	const [context, setContext] = useState(null);

	useEffect(() => {
		function handleMessage(event) {
			if (typeof event.data !== "object" || !event.data.widget) {
				return;
			}

			const data = event.data.widget;

			if (data.config) {
				setConfig(data.config);
				setLoading(false);
			}
			if (data.context) {
				setContext((ctx) => Object.assign({}, ctx, data.context));
			}
		}
		window.addEventListener("message", handleMessage, false);

		window.parent &&
			window.parent.postMessage({ widget: { hello: true } }, "*");

		return () =>
			window.removeEventListener("message", handleMessage, false);
	}, []);

	return (
		<div
			className={classnames(
				styles.widget,
				loading ? styles.loading : null
			)}
		>
			{loading ? (
				<Spinner />
			) : config ? (
				<WidgetContents
					config={config}
					context={context || {}}
					onHeightChanged={_postWidgetHeight}
				/>
			) : (
				"Error"
			)}
		</div>
	);
}

function Spinner() {
	return (
		<div className={styles.spinner}>
			<IconLoader className={styles.spinner__icon} size={50} />
		</div>
	);
}

function _postWidgetHeight(height) {
	window.parent && window.parent.postMessage({ widget: { height } }, "*");
}
