import styles from "./VideoCall.module.css";
import audioSprite from "./VideoCall.sprite.mp3";

import VideoPlayer from "@faintlines/video-player";

import React, { useState, useEffect, useRef, forwardRef } from "react";
import { Howl } from "howler";
import classnames from "classnames";

const DEFAULT_AUDIO_SPRITE_CONFIG = {
	src: [audioSprite],
	volume: 0.5,
	sprite: {
		hangup: [0, 2220.408163265306],
		ringtone: [4000, 4513.832199546485, true],
	},
};

export default function VideoCall({
	name,
	videoUrl,
	aspectRatio,
	audioSpriteConfig,
	maxRingTimeSec,
	onEnded,
}) {
	const [status, setStatus] = useState("ringing");
	const [declined, setDeclined] = useState(false);
	const videoRef = useRef();
	const audioRef = useRef(null);
	const ringtoneSoundIdRef = useRef(null);

	useEffect(() => {
		audioRef.current = new Howl(audioSpriteConfig);
		return () => audioRef.current.unload();
	}, [audioSpriteConfig]);

	useEffect(() => {
		if (status === "ringing") {
			ringtoneSoundIdRef.current = audioRef.current.play("ringtone");
		} else if (ringtoneSoundIdRef.current) {
			audioRef.current.stop(ringtoneSoundIdRef.current);
			ringtoneSoundIdRef.current = null;
		}
	}, [status]);

	function answerHandler() {
		videoRef.current.play();
		setStatus("active");
	}

	function hangupHandler() {
		audioRef.current.play("hangup");
		setStatus("ended");
	}

	function endedHandler() {
		onEnded && onEnded(declined);
	}

	function callDeclineHandler() {
		setDeclined(true);
		setStatus("ended");
		audioRef.current.play("hangup");
	}

	return (
		<div className={styles.videoCall}>
			{status !== "ended" ? (
				<ActiveVideoCall
					ref={videoRef}
					videoUrl={videoUrl}
					aspectRatio={aspectRatio}
					onHangup={hangupHandler}
				/>
			) : null}
			{status === "ended" ? (
				<EndedCall
					title={name}
					subtitle={declined ? "Call Declined" : "Call Ended"}
					timeoutSec={3}
					onTimeout={endedHandler}
				/>
			) : null}
			{status === "ringing" ? (
				<IncomingCall
					name={name}
					onAnswer={answerHandler}
					onDecline={callDeclineHandler}
				/>
			) : null}
		</div>
	);
}

VideoCall.defaultProps = {
	audioSpriteConfig: DEFAULT_AUDIO_SPRITE_CONFIG,
};

function IncomingCall({ name, maxRingTimeSec, onAnswer, onDecline }) {
	useEffect(() => {
		if (maxRingTimeSec) {
			const timeout = setTimeout(() => {
				onDecline && onDecline();
			}, maxRingTimeSec * 1000);

			return () => clearTimeout(timeout);
		}
	}, [maxRingTimeSec, onDecline]);

	return (
		<div className={styles.incoming}>
			<div className={styles.incoming__title}>{name}</div>
			<div className={styles.incoming__subtitle}>
				{"Incoming video call"}
			</div>
			<div className={styles.incoming__buttons}>
				<PhoneButton type="answer" onClick={onAnswer} />
				<PhoneButton type="decline" onClick={onDecline} />
			</div>
		</div>
	);
}

function EndedCall({ title, subtitle, timeoutSec, onTimeout }) {
	const timeoutFired = useRef(false);

	useEffect(() => {
		if (timeoutSec) {
			const timeout = setTimeout(() => {
				if (!timeoutFired.current) {
					onTimeout && onTimeout();
					timeoutFired.current = true;
				}
			}, timeoutSec * 1000);

			return () => clearTimeout(timeout);
		}
	}, [timeoutSec, onTimeout]);

	return (
		<div className={classnames(styles.incoming, styles.ended)}>
			<div className={styles.incoming__title}>{title}</div>
			<div className={styles.incoming__subtitle}>{subtitle}</div>
		</div>
	);
}

const ActiveVideoCall = forwardRef(function ActiveVideoCall(
	{ videoUrl, aspectRatio, onHangup },
	ref
) {
	const [started, setStarted] = useState(false);

	return (
		<>
			<Video
				ref={ref}
				videoUrl={videoUrl}
				aspectRatio={aspectRatio}
				onEnded={onHangup}
				onStarted={() => setStarted(true)}
			/>
			<PhoneButton type="end" onClick={onHangup} />
			{started ? null : (
				<div className={styles.video__loading}>{"Connecting..."}</div>
			)}
		</>
	);
});

const Video = forwardRef(function Video(
	{ videoUrl, aspectRatio, onStarted, onEnded },
	ref
) {
	const screenRef = useRef();
	const [[width, height], setSize] = useState([0, 0]);
	const haveMeasure = !!(width && height);

	useEffect(() => {
		function resizeHandler() {
			setSize([
				screenRef.current.offsetWidth,
				screenRef.current.offsetHeight,
			]);
		}
		resizeHandler();
		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, []);

	const [videoWidth, videoHeight] = haveMeasure
		? calcVideoSize(width, height, aspectRatio)
		: [undefined, undefined];

	if (typeof videoUrl === "string") {
		videoUrl = { file: videoUrl };
	}

	return (
		<div className={styles.videoWrapper} ref={screenRef}>
			{haveMeasure ? (
				<VideoPlayer
					ref={ref}
					hlsUrl={videoUrl.hls}
					dashUrl={videoUrl.dash}
					fileUrl={videoUrl.file}
					width={videoWidth}
					height={videoHeight}
					className={styles.video__player}
					playsInline
					preload="auto"
					onEnded={onEnded}
					onCanPlay={onStarted}
				/>
			) : null}
		</div>
	);
});

function PhoneButton({ type, onClick }) {
	return (
		<div
			className={classnames(styles.phoneButton, styles[type])}
			onClick={onClick}
		>
			<i className={classnames(styles.phoneButton__icon, "icon-phone")} />
		</div>
	);
}

export function calcVideoSize(screenWidth, screenHeight, videoAR) {
	const screenAR = screenWidth / screenHeight;
	const screenLandscape = screenAR > 1;
	const videoLandscape = videoAR > 1;
	const cover = screenLandscape === videoLandscape;

	let videoWidth, videoHeight;

	if (cover === !!(screenAR > videoAR)) {
		videoWidth = screenWidth;
		videoHeight = screenWidth * (1 / videoAR);
	} else {
		videoHeight = screenHeight;
		videoWidth = screenHeight * videoAR;
	}

	return [videoWidth, videoHeight];
}
