import { createTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

const white = {
	light: "#fff",
	main: "#fff",
	dark: "#fff",
	contrastText: "#000",
};

const fontFamily = [
	"Raleway",
	'"Helvetica Neue"',
	"Arial",
	"sans-serif",
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"',
].join(",");

export const THEME_LIGHT = createTheme({
	typography: {
		fontFamily,
	},
});

export const THEME_DARK = createTheme({
	palette: {
		type: "dark",
		primary: blue,
		error: {
			light: "#ff7961",
			main: "#ff7961",
			dark: "#ff7961",
			contrastText: "#000",
		},
	},
	typography: {
		fontFamily,
	},
});

export const THEME_ADVENTURE_ONBOARDING = createTheme({
	palette: {
		type: "dark",
		primary: white,
		error: {
			light: "#d21f3c",
			main: "#d21f3c",
			dark: "#d21f3c",
			contrastText: "#000",
		},
	},
	typography: {
		fontSize: 16,
		fontFamily,
	},
});
