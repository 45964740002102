import styles from "./PlayPage.module.css";
import Wizards from "../components/stories/Wizards";
import Darknet from "../components/stories/Darknet";
import Museum from "../components/stories/Museum";
import Anchor from "../components/stories/Anchor";
import Twins from "../components/stories/Twins";
import TheLostTomb from "../components/stories/TheLostTomb";
import Phoenix from "../components/stories/Phoenix";
import CrimeOfPassion from "../components/stories/CrimeOfPassion";
import { useUserStore } from "../stores";
import { pageTitleStory } from "../config";

import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Loader as IconLoader } from "lucide-react";

// TODO: lazy load
const STORY_COMPONENTS = {
    wizards: Wizards,
    darknet: Darknet,
    museum: Museum,
    "the-anchor": Anchor,
    twins: Twins,
    "the-lost-tomb": TheLostTomb,
    phoenix: Phoenix,
    "crime-of-passion": CrimeOfPassion,
};

export default function PlayPage({ story }) {
    if (!story) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Helmet>
                <title>{pageTitleStory(story.title)}</title>
            </Helmet>
            <Contents story={story} />
        </>
    );
}

const Contents = observer(function ({ story }) {
    const userStore = useUserStore();
    const { userLoaded, loggedIn } = userStore;

    if (!userLoaded) {
        return (
            <div className={styles.spinner}>
                <IconLoader className={styles.spinner__icon} size={100} />
            </div>
        );
    }

    if (!loggedIn) {
        return <Redirect to={`/story/${story.id}`} />;
    }

    const StoryComp = STORY_COMPONENTS[story.id];

    if (!StoryComp) {
        return null;
    }

    return <StoryComp story={story} />;
});
