import styles from "./DataCollectionForm.module.css";
import ResendMessage from "./ResendMessage";
import * as Backend from "../../../advStoreClient";
import PhoneNumberField from "../../forms/PhoneNumberField";
import OTPField from "../../forms/OTPField";
import { THEME_ADVENTURE_ONBOARDING } from "../../../muiThemes";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/core/styles";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { observer } from "mobx-react-lite";
import pick from "lodash/pick";
import classnames from "classnames";

const DEFAULT_COLLECT_TITLE =
	"Before we start, please provide the following details.";
const DEFAULT_UNEXPECTED_ERROR_MESSAGE =
	"Alas! Something terrible has happened! Please try again.";

const VALIDATION_SCHEMA_FIELDS = {
	phone: yup.string("Your phone").required("Phone is required"),
	email: yup
		.string("Your email")
		.email("Enter a valid email")
		.required("Email is required"),
};

function DataCollectionForm({
	story,
	missingInfo,
	collectTitle,
	unexpectedErrorMessage,
	onComplete,
}) {
	const [remainingKeys, setRemainingKeys] = useState(missingInfo);
	const [step, setStep] = useState("collect");
	const [collectResponse, setCollectResponse] = useState(null);
	const currentKey = remainingKeys[0];

	function handleCollectComplete(response) {
		if (step === "collect") {
			setCollectResponse(response);
		}

		if (step === "collect" && response.verify) {
			setStep("verify");
		} else {
			if (remainingKeys.length === 1) {
				onComplete();
			} else {
				setRemainingKeys((x) => x.slice(1));
				setStep("collect");
			}
		}
	}

	return (
		<ThemeProvider theme={THEME_ADVENTURE_ONBOARDING}>
			<div>
				{step === "collect" ? (
					<CollectForm
						title={collectTitle}
						dataKey={currentKey}
						story={story}
						missingInfo={missingInfo}
						unexpectedErrorMessage={unexpectedErrorMessage}
						onComplete={handleCollectComplete}
					/>
				) : step === "verify" ? (
					<VerificationForm
						verifyKey={currentKey}
						story={story}
						missingInfo={missingInfo}
						collectResponse={collectResponse}
						unexpectedErrorMessage={unexpectedErrorMessage}
						onComplete={handleCollectComplete}
					/>
				) : null}
			</div>
		</ThemeProvider>
	);
}
DataCollectionForm.defaultProps = {
	unexpectedErrorMessage: DEFAULT_UNEXPECTED_ERROR_MESSAGE,
};

export default observer(DataCollectionForm);

function CollectForm({
	story,
	dataKey,
	title,
	unexpectedErrorMessage,
	onComplete,
}) {
	const [error, setError] = useState(null);

	const formik = useFormik({
		initialValues: {
			[dataKey]: "",
			saveToProfile: true,
		},
		validationSchema: yup.object(pick(VALIDATION_SCHEMA_FIELDS, [dataKey])),
		enableReinitialize: true,
		onSubmit: (values, actions) => {
			setError(null);
			Backend.storyCollectDetail({
				storyId: story.id,
				key: dataKey,
				value: values[dataKey],
				saveToProfile: values.saveToProfile,
			})
				.then(({ data }) => {
					if (data.success) {
						onComplete(data);
					} else {
						setError(data.error || unexpectedErrorMessage);
					}
				})
				.catch(() => {
					setError(unexpectedErrorMessage);
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		},
	});

	return (
		<Form
			submitting={formik.isSubmitting}
			onSubmit={formik.handleSubmit}
			error={error}
		>
			<FormTitle>{title || DEFAULT_COLLECT_TITLE}</FormTitle>
			{dataKey === "phone" ? (
				<PhoneNumberField
					mui
					className={styles.form__field}
					id="collect-phone"
					name="phone"
					label="Your phone"
					variant="outlined"
					type="tel"
					onChange={(value) => formik.setFieldValue("phone", value)}
					error={formik.touched.phone && Boolean(formik.errors.phone)}
					helperText={formik.touched.phone && formik.errors.phone}
				/>
			) : dataKey === "email" ? (
				<TextField
					className={styles.form__field}
					id="collect-email"
					name="email"
					label="Your email"
					variant="outlined"
					type="email"
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
				/>
			) : null}
			<FormControlLabel
				className={styles.form__field}
				control={
					<Checkbox
						id="collect-id"
						checked={formik.values.saveToProfile}
						onChange={formik.handleChange}
						name="saveToProfile"
						color="primary"
					/>
				}
				label="Save for future adventures"
			/>
			<SubmitButton>{"Continue"}</SubmitButton>
		</Form>
	);
}

function VerificationForm({
	verifyKey,
	story,
	collectResponse,
	unexpectedErrorMessage,
	onComplete,
}) {
	const [valid, setValid] = useState(false);
	const [otp, setOtp] = useState("");
	const [error, setError] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const otpLength = collectResponse.otp_length || 6;
	const otpSentTo = collectResponse.otp_sent_to || `your ${verifyKey}`;

	function submitOtp(otp) {
		setError(null);
		setSubmitting(true);
		Backend.storyVerifyInfo({
			storyId: story.id,
			key: verifyKey,
			otp,
		})
			.then(({ data }) => {
				if (data.success) {
					onComplete(data);
				} else {
					setError(data.error || unexpectedErrorMessage);
				}
			})
			.catch(() => {
				setError(unexpectedErrorMessage);
			})
			.finally(() => {
				setSubmitting(false);
			});
	}

	function handleSubmit(evt) {
		evt && evt.preventDefault();
		submitOtp(otp);
	}

	function handleOtpChange(key, value) {
		setOtp(value);
		const isValid = value && value.length === otpLength;
		setValid(isValid);
		if (isValid) {
			submitOtp(value);
		}
	}

	function handleOtpResend() {
		setError(null);
		Backend.storyResendOtp({ storyId: story.id, key: verifyKey })
			.then(({ data }) => {
				if (!data.success) {
					setError(data.error || unexpectedErrorMessage);
				}
			})
			.catch(() => {
				setError(unexpectedErrorMessage);
			});
	}

	return (
		<Form submitting={submitting} onSubmit={handleSubmit} error={error}>
			<FormTitle>{`Please, kindly type the verification code sent to ${otpSentTo}`}</FormTitle>
			<OTPField
				length={otpLength}
				onChange={handleOtpChange}
				className={styles.form__field}
			/>
			<SubmitButton disabled={!valid}>{"Continue"}</SubmitButton>
			<ResendMessage
				text="Didn't receive the code?"
				onResend={handleOtpResend}
			/>
		</Form>
	);
}

function Form({ submitting, error, children, onSubmit }) {
	return (
		<form
			className={classnames(
				styles.form,
				submitting ? styles.submitting : null
			)}
			onSubmit={onSubmit}
			noValidate
		>
			{children}
			<div className={styles.form__error}>{error}</div>
		</form>
	);
}

function FormTitle({ children }) {
	return <Typography variant="subtitle1">{children}</Typography>;
}

function SubmitButton({ children, ...props }) {
	return (
		<Button
			variant="contained"
			color="primary"
			type="submit"
			{...props}
			className={styles.form__field}
		>
			{children}
		</Button>
	);
}
