import styles from "./SignInModal.module.css";
import Button from "../forms/Button";
import { useUiStore, useUserStore } from "../../stores";

import { observer } from "mobx-react-lite";
import classnames from "classnames";

const SignInModal = observer(function ({ storyId, storiesById }) {
	const uiStore = useUiStore();
	const userStore = useUserStore();
	const { signInModalVisible, signInModalMessage } = uiStore;

	if (!signInModalVisible) {
		return null;
	}

	const closeHandler = () => uiStore.hideSignInModal();

	return (
		<div className={classnames(styles.wrapper)} onClick={closeHandler}>
			<div
				className={styles.modal}
				onClick={(evt) => evt.stopPropagation()}
			>
				<div className={styles.header}>
					<div className={styles.closeButton} onClick={closeHandler}>
						{"✕"}
					</div>
					<div className={styles.title}>{"Log in or sign up"}</div>
				</div>
				<div className={styles.contents}>
					{signInModalMessage || "Welcome to the Adventure Store!"}
					<Button
						className={styles.signinButton}
						outlined
						fullWidth
						onClick={() => userStore.loginWithFacebook()}
					>
						{"Continue with Facebook"}
					</Button>
					<Button
						className={styles.signinButton}
						outlined
						fullWidth
						onClick={() => userStore.loginWithGoogle()}
					>
						{"Continue with Google"}
					</Button>
				</div>
			</div>
		</div>
	);
});

export default SignInModal;
