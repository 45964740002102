import styles from "./Wizards.module.css";
import Story from "./common/Story";
import { Tile } from "./common/storyCommons";
import ResendMessage from "./common/ResendMessage";

import VideoPlayer from "@faintlines/video-player";

import { useRef, useState, useEffect, forwardRef } from "react";
import classnames from "classnames";
import forOwn from "lodash/forOwn";

const INTRO_VIDEO_URL = {
	file: "https://advusercontent.com/wizards-intro-2.mp4",
	hls: "https://stream.mux.com/z7cfV701rrYF2oO3urlpIMxWApjy01Zh6FpH1sQZdyboo.m3u8",
	// hls: "https://videodelivery.net/b8b664279c3110abb435e9bd9f32736a/manifest/video.m3u8",
	// dash: "https://videodelivery.net/b8b664279c3110abb435e9bd9f32736a/manifest/video.mpd",
};

const VIDEO_EVENTS = {
	text1: 123,
	text2: 165,
};
const VIDEO_SKIP_TIME = 165;
const VIDEO_END_TIME = 170;

export default function Wizards({ story }) {
	return (
		<Story
			story={story}
			transitionAnimation="animation1"
			transitionVideoUrl={
				require("../../images/stories/wizards/smoke1.mp4").default
			}
			introBullets={INTRO_BULLETS}
			TilesComponent={TilesComponent}
			widgetTitle="Hello, Wizard!"
			dataCollectionTitle="Before commencing, forgive a few small details!"
			unexpectedErrorMessage="Merlin's Beard! Something terrible has happened! We humbly entreat you to try again."
			className={styles.story}
		/>
	);
}

function TilesComponent({
	firstStep,
	currentStep,
	onSetStep,
	onStartStory,
	onInteract,
}) {
	function handleStep3Event(eventName) {
		if (eventName === "text1") {
			onStartStory();
		} else if (eventName === "text2") {
			onInteract("send_directions_sms");
		}
	}

	function handleStep3Resend(eventName) {
		onInteract("resend_oracle_sms");
	}

	return (
		<>
			<Tile
				name="intro"
				fullWidth
				overflow="hidden"
				stepNumber={firstStep}
				currentStep={currentStep}
			>
				<IntroductionVideo
					onTriggerEvent={handleStep3Event}
					onResend={handleStep3Resend}
				/>
			</Tile>
		</>
	);
}

const INTRO_BULLETS = [
	{
		icon: "globe",
		content: (
			<>
				{
					"Prepare to revel in an immersive and interactive experience that all takes place in the real world! "
				}
				<i>{"Huzzah!"}</i>
			</>
		),
	},
	{
		icon: "mobile",
		content: (
			<>
				{
					"To fully rejoice in the experience, you will be best served by procuring a phone capable of sending and receiving text messages. "
				}
				<i>{"Text on!"}</i>
			</>
		),
	},
	{
		icon: "headphones-1",
		content: (
			<>
				{
					"To interact competently with our cast of characters, we recommend wearing headphones upon your ears and utilizing a capable microphone. A mobile phone is an excellent choice! "
				}
				<i>{"Well played!"}</i>
			</>
		),
	},
	{
		icon: "megaphone",
		content: (
			<>
				{
					"Good players, this experience is still a work in progress, and your feedback is most critical to improving it. Please report to us anything unclear or broken and it will be addressed! "
				}
				<i>{"Critique!"}</i>
			</>
		),
	},
];

function IntroductionVideo({ onTriggerEvent, onResend }) {
	const videoRef = useRef();
	const [playing, setPlaying] = useState(false);
	const eventTriggered = useRef({});
	const [showSkip, setShowSkip] = useState(true);
	const [showResend, setShowResend] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			const video = videoRef.current;
			if (!video) {
				return;
			}

			forOwn(VIDEO_EVENTS, (time, name) => {
				if (
					video.currentTime >= time &&
					!eventTriggered.current[name]
				) {
					console.log("trigger", name);
					onTriggerEvent && onTriggerEvent(name);
					eventTriggered.current[name] = true;
				}
			});

			if (video.currentTime >= VIDEO_SKIP_TIME) {
				setShowSkip(false);
			}

			if (video.currentTime >= VIDEO_END_TIME) {
				setShowResend(true);
			}
		}, 500);
		return () => clearInterval(interval);
	});

	function playClickHandler() {
		videoRef.current.play();
		setPlaying(true);
	}

	function skipHandler() {
		setShowSkip(false);
		if (videoRef.current.seekTo) {
			videoRef.current.seekTo(VIDEO_SKIP_TIME);
		} else {
			videoRef.current.currentTime = VIDEO_SKIP_TIME;
		}
	}

	return (
		<>
			<div className={styles.intro}>
				<IntroVideo ref={videoRef} />
				<div
					className={classnames(
						styles.intro__playButton,
						playing ? styles.playing : null
					)}
					onClick={playClickHandler}
				>
					<i className="icon-play" />
				</div>
			</div>
			<div
				className={classnames(
					styles.intro__skipButton,
					playing && showSkip ? styles.visible : null
				)}
				onClick={skipHandler}
			>
				{"Skip introduction"}
			</div>
			{showResend ? (
				<ResendMessage
					text="Didn't receive two messages from the Oracle?"
					onResend={onResend}
				/>
			) : null}
		</>
	);
}

const IntroVideo = forwardRef((props, ref) => {
	return (
		<VideoPlayer
			ref={ref}
			className={styles.intro__video}
			url={INTRO_VIDEO_URL}
			width="100%"
			height="auto"
		/>
	);
});

// TODO: reimplement
//
// const widgetHints =
// 		step === 3
// 			? {
// 					method: "immediate",
// 					hints: [
// 						{
// 							text: "Check your phone for text messages from the Oracle. They should direct you to the Wizards Convention.",
// 						},
// 					],
// 			  }
// 			: null;
