import styles from "./Museum.module.css";
import Story from "./common/Story";
import { Tile } from "./common/storyCommons";
import ResendMessage from "./common/ResendMessage";
import VideoCall from "./common/VideoCall";

export default function CrimeOfPassion({ story }) {
    return (
        <Story
            story={story}
            transitionAnimation="animation2"
            introBullets={INTRO_BULLETS}
            TilesComponent={TilesComponent}
            className={styles.story}
        />
    );
}

function TilesComponent({
    firstStep,
    currentStep,
    onSetStep,
    onStartStory,
    onInteract,
}) {
    function handleVideoCallEnded() {
        onStartStory();
        onSetStep(firstStep + 1);
    }

    function handleResendMessage() {
        onInteract("resend_sms");
    }

    return (
        <>
            <Tile stepNumber={firstStep} fullWidth currentStep={currentStep}>
                <VideoCall
                    name="Virginia"
                    videoUrl={{
                        file: "https://advusercontent.com/crime-of-passion/chat/videos/low/virginia-intro.mp4   ",
                        hls: "https://stream.mux.com/QDrx5tOGaXwvDe5JeYJLPF5lKxux9axBkDw2Q4Ex6ts.m3u8",
                    }}
                    aspectRatio={9 / 16}
                    onEnded={handleVideoCallEnded}
                />
            </Tile>
            <Tile stepNumber={firstStep + 1} currentStep={currentStep}>
                <PostVideoText onResend={handleResendMessage} />
            </Tile>
        </>
    );
}

const INTRO_BULLETS = [
    {
        icon: "globe",
        content:
            "Prepare to revel in an immersive and interactive adventure that all takes place in the real world!",
    },
    {
        icon: "mobile",
        content:
            "To fully rejoice in the adventure, you will be best served by procuring a phone capable of sending and receiving text messages and calls.",
    },
    {
        icon: "megaphone",
        content:
            "Good players, this adventure is still a work in progress, and your feedback is most critical to improving it. Please report to us anything unclear or broken and it will be addressed!",
    },
];

function PostVideoText({ onResend }) {
    return (
        <div className={styles.intro}>
            <p>
                {
                    "Help Aunty Virginia retrieve her, rightfully earned, position in The Blind Tiger."
                }
            </p>
            <p>
                <i>{"Don't forget to check your text messages."}</i>
            </p>
            <ResendMessage
                text="Didn't receive any text message?"
                onResend={onResend}
            />
        </div>
    );
}
