import styles from "./Button.module.css";
import classnames from "classnames";

export default function Button({
	children,
	className,
	outlined,
	fullWidth,
	disabled,
	onClick,
}) {
	return (
		<button
			className={classnames(
				styles.button,
				className,
				outlined ? styles.outlined : null,
				disabled ? styles.disabled : null,
				fullWidth ? styles.fullWidth : null
			)}
			onClick={onClick}
		>
			{children}
		</button>
	);
}
