import { apiCall, configure } from "@faintlines/backend-client";
import { DEBUG } from "./config";

configure({
    baseUrl: DEBUG ? `http://${window.location.hostname}:9090/` : "/",
});

export function getIpInfo() {
    return _apiCall("ip-info", "get", {
        timingLabel: "getIpInfo",
    });
}

export function getUserInfo() {
    return _apiCall("account", "get", {
        timingLabel: "getUserInfo",
    });
}

export function getCompleteSignupInfo() {
    return _apiCall("account/complete-signup", "get", {
        timingLabel: "completeSignupGetInfo",
    });
}

export function completeSignup(data) {
    return _apiCall("account/complete-signup", "put", {
        timingLabel: "completeSignup",
        data,
    });
}

export function logout() {
    return _apiCall("auth/logout", "put", {
        timingLabel: "logout",
    });
}

export function userUpdatePreference(key, value) {
    return _apiCall("account/pref", "put", {
        data: { key, value },
        timingLabel: "userUpdatePreference",
    });
}

export function userUpdateName(firstName, lastName) {
    return _apiCall("account/name", "put", {
        data: { first_name: firstName, last_name: lastName },
        timingLabel: "userUpdateName",
    });
}

export function userVerifyPhone({ phone }) {
    return _apiCall("account/phone/verify", "put", {
        data: { phone },
        timingLabel: "userVerifyPhone",
    });
}

export function userUpdatePhone({ phone, verify, otp }) {
    return _apiCall("account/phone", "put", {
        data: { phone, verify, otp },
        timingLabel: "userUpdatePhone",
    });
}

export function userDeletePhone() {
    return _apiCall("account/phone", "delete", {
        timingLabel: "userDeletePhone",
    });
}

export function storyCreateSession({ storyId }) {
    return _apiCall(`story/${storyId}/create-session`, "put", {
        timingLabel: "storyCreateSession",
    });
}

export function storyCollectDetail({ storyId, key, value, saveToProfile }) {
    return _apiCall(`story/${storyId}/collect-detail`, "put", {
        data: {
            key,
            value,
            save_to_profile: saveToProfile,
        },
        timingLabel: "storyCollectDetail",
    });
}

export function storyResendOtp({ storyId, key }) {
    return _apiCall(`story/${storyId}/resend-otp`, "put", {
        data: { key },
        timingLabel: "storyResendOtp",
    });
}

export function storyVerifyInfo({ storyId, key, otp }) {
    return _apiCall(`story/${storyId}/verify-info`, "put", {
        data: { key, otp },
        timingLabel: "storyVerifyInfo",
    });
}

export function storyStart({ storyId }) {
    return _apiCall(`story/${storyId}/start`, "put", {
        timingLabel: "storyStart",
    });
}

export function storyInteractOnboarding({ storyId, interactionType, data }) {
    return _apiCall(`story/${storyId}/interact`, "put", {
        data: {
            asset_id: "onboarding",
            interaction_type: interactionType,
            session_id: null,
            data: data || {},
        },
        timingLabel: "storyInteractOnboarding",
    });
}

export function storyInteractEnding({
    storyId,
    sessionId,
    endingId,
    interactionType,
    data,
}) {
    return _apiCall(`story/${storyId}/interact`, "put", {
        data: {
            asset_id: "ending",
            interaction_type: interactionType,
            session_id: sessionId,
            data: { ending_id: endingId, ...(data || {}) },
        },
        timingLabel: "storyInteractEnding",
    });
}

export function storyFeedbackEnding({
    storyId,
    text,
    rating,
    sessionId,
    endingId,
}) {
    return apiCall(`story/${storyId}/feedback`, "put", {
        data: {
            text,
            rating,
            asset_id: "ending",
            session_id: sessionId,
            context: endingId,
        },
        timingLabel: "storyFeedback",
    });
}

function _apiCall(url, method, options) {
    options = options || {};
    options.extra = options.extra || {};
    options.extra.withCredentials = true;
    return apiCall(url, method, options);
}
