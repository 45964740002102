import styles from "./OnScreenAlert.module.css";
import notification1 from "./notification1.mp3";

import { MarkdownContent } from "@faintlines/markdown";

import React, { useEffect, useRef } from "react";
import { AlertTriangle as IconAlertTriangle } from "lucide-react";
import { Howl } from "howler";

const ICONS = {
	alert: IconAlertTriangle,
};

const SOUNDS = {
	notification1,
};

export default function OnScreenAlert({
	title,
	text,
	icon,
	sound,
	footerChildren,
}) {
	const IconComp = ICONS[icon];
	const soundRef = useRef();

	useEffect(() => {
		const soundSrc = SOUNDS[sound];
		if (sound) {
			soundRef.current = new Howl({ src: [soundSrc], volume: 0.5 });
			setTimeout(() => {
				soundRef.current.play();
			}, 1000);
			return () => soundRef.current.unload();
		}
	}, []); // eslint-disable-line

	return (
		<div className={styles.alert}>
			<div className={styles.body}>
				{IconComp ? (
					<IconComp className={styles.icon} size="2em" />
				) : null}
				<div className={styles.body__contents}>
					{title ? <div className={styles.title}>{title}</div> : null}
					{text ? (
						<MarkdownContent
							className={styles.text}
							markdown={text}
						/>
					) : null}
				</div>
			</div>
			{footerChildren ? (
				<div className={styles.footer}>{footerChildren}</div>
			) : null}
		</div>
	);
}
