import styles from "./StoryModal.module.css";
import {
	StoryPlayButton,
	StoryDownloadButton,
} from "./common/StoryStartButtons";
import { pageTitleStory } from "../config";

import { Link } from "react-router-dom";
import classnames from "classnames";
import { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function StoryModal({ storyId, storiesById }) {
	// unloadingStoryId holds the previous storyId for a bit longer, to render
	// the modal while it's transitioning out.
	const [unloadingStoryId, setUnloadingStoryId] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (storyId) {
			setUnloadingStoryId(storyId);
			return;
		}

		const timeout = setTimeout(() => setUnloadingStoryId(null), 500);
		return () => clearTimeout(timeout);
	}, [storyId]);

	const actualStoryId = storyId || unloadingStoryId;

	if (!actualStoryId) {
		return null;
	}

	const exiting = !!(unloadingStoryId && !storyId);
	const story = storiesById[actualStoryId];

	if (!story) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={classnames(
				styles.wrapper,
				exiting ? styles.exiting : null
			)}
			onClick={() => history.push("/")}
		>
			<Helmet>
				<title>{pageTitleStory(story.title)}</title>
			</Helmet>
			<div
				className={styles.modal}
				onClick={(evt) => evt.stopPropagation()}
			>
				<Link to="/" className={styles.closeButton}>
					{"✕"}
				</Link>
				<Header story={story} />
				<div className={styles.modal__inner}>
					<p className={styles.description}>
						{story.descriptionLong || story.description}
					</p>
				</div>
			</div>
		</div>
	);
}

function Header({ story }) {
	const { title, logo, video, poster, posterNoLogo, downloadUrl } = story;
	const [videoPlaying, setVideoPlaying] = useState(false);
	const showLogo = videoPlaying || !!posterNoLogo;

	return (
		<div className={styles.header}>
			{video ? (
				<HeaderVideo
					story={story}
					onStart={() => setVideoPlaying(true)}
					onEnded={() => setVideoPlaying(false)}
				/>
			) : null}
			<img
				className={styles.poster}
				src={posterNoLogo || poster}
				alt={title}
			/>
			<div className={styles.header__contents}>
				<h2
					className={classnames(
						styles.title,
						showLogo ? styles.visible : null
					)}
				>
					<img
						className={styles.title__image}
						src={logo}
						alt={title}
					/>
				</h2>
				{downloadUrl ? (
					<StoryDownloadButton url={downloadUrl} />
				) : (
					<StoryPlayButton story={story} />
				)}
			</div>
		</div>
	);
}

function HeaderVideo({ story, onStart, onEnded }) {
	const [playing, setPlaying] = useState(false);

	function handleStarted() {
		setPlaying(true);
		onStart && onStart();
	}

	function handleEnded() {
		setPlaying(false);
		onEnded && onEnded();
	}

	return (
		<video
			src={story.video}
			className={classnames(
				styles.video,
				playing ? styles.playing : null
			)}
			preload="auto"
			autoPlay
			muted
			playsInline
			disableRemotePlayback
			disablePictureInPicture
			onPlay={handleStarted}
			onEnded={handleEnded}
		/>
	);
}
