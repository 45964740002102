import styles from "./Phoenix.module.css";
import Story from "./common/Story";
import { Tile } from "./common/storyCommons";
import ResendMessage from "./common/ResendMessage";
import IntroVideo from "./common/IntroVideo";
import OnScreenAlert from "./common/OnScreenAlert";

import React from "react";

export default function Phoenix({ story }) {
	return (
		<Story
			story={story}
			transitionAnimation="animation2"
			introBullets={INTRO_BULLETS}
			TilesComponent={TilesComponent}
			className={styles.story}
		/>
	);
}

function TilesComponent({
	firstStep,
	currentStep,
	onStartStory,
	onSetStep,
	onInteract,
}) {
	const handleResend = () => {
		onInteract("resend");
	};

	const handleVideoEnd = () => {
		onSetStep(firstStep + 1);
		onStartStory();
	};

	return (
		<>
			<Tile fullWidth stepNumber={firstStep} currentStep={currentStep}>
				<IntroVideo
					url={{
						file: "https://advusercontent.com/phoenix/videos/scene1-1.mp4",
						hls: "https://stream.mux.com/jsITvD6WlKMxzGis6XUeZfpKzPyp9vtQcvTTwqfjIvk.m3u8",
					}}
					autoPlay
					skipText="Skip introduction"
					onEnded={handleVideoEnd}
				/>
			</Tile>
			<Tile stepNumber={firstStep + 1} currentStep={currentStep}>
				<OnScreenAlert
					icon="alert"
					sound="notification1"
					title="MISSING PERSONS ALERT"
					text={
						"Last seen on Phoenix Interstate.\n2 male and 2 female, Ages 25 - 35, seemingly mentally impaired.\n\nIf seen contact 1 86 AZ 555 0174."
					}
					footerChildren={<AlertFooter onResend={handleResend} />}
				/>
			</Tile>
		</>
	);
}

const INTRO_BULLETS = [
	{
		icon: "globe",
		content:
			"Prepare to revel in an immersive and interactive adventure that all takes place in the real world!",
	},
	{
		icon: "mobile",
		content:
			"To fully rejoice in the adventure, you will be best served by procuring a phone capable of sending and receiving text messages and emails.",
	},
	{
		icon: "megaphone",
		content:
			"Good players, this adventure is still a work in progress, and your feedback is most critical to improving it. Please report to us anything unclear or broken and it will be addressed!",
	},
];

const AlertFooter = ({ onResend }) => (
	<div className={styles.alertFooter}>
		<p>
			<i>{"Don't forget to check your text messages."}</i>
		</p>
		<ResendMessage text="Didn't receive a text?" onResend={onResend} />
	</div>
);
