import styles from "./OTPField.module.css";
import OtpInput from "./OtpInput";

import { useState } from "react";

const DEFAULT_LENGTH = 6;

export default function OTPField({
	length,
	dataKey,
	shouldAutoFocus,
	className,
	onChange,
}) {
	const [value, setValue] = useState("");

	function handleChange(otp) {
		setValue(otp);
		onChange && onChange(dataKey, otp);
	}

	return (
		<OtpInput
			containerStyle={styles.field}
			inputStyle={styles.field__input}
			shouldAutoFocus={shouldAutoFocus}
			isInputNum={true}
			value={value}
			className={className}
			onChange={handleChange}
			numInputs={length || DEFAULT_LENGTH}
		/>
	);
}
