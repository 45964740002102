import "./PhoneNumberField.css";
import TextField from "./TextField";
import MuiTextField from "@material-ui/core/TextField";
import { useUserStore } from "../../stores/UserStore";

import { useRef, useEffect, useCallback } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function PhoneNumberField({
	dataKey,
	onChange,
	mui,
	className,
	defaultValue,
	...props
}) {
	const { countryCode } = useUserStore();
	const inputRef = useRef();
	const itiRef = useRef();

	const handleChange = useCallback(() => {
		const phoneNumber = inputRef.current.value;
		const countryData = itiRef.current.getSelectedCountryData();
		const countryCode = countryData.iso2 || countryData.dialCode;

		if (onChange) {
			const newValue = `${countryCode}|${phoneNumber}`;
			if (mui) {
				onChange(newValue);
			} else {
				onChange(dataKey, newValue);
			}
		}

		// utils are lazy loaded
		if (typeof window.intlTelInputUtils !== "undefined") {
			const currentText = itiRef.current.getNumber(
				window.intlTelInputUtils.numberFormat.E164
			);
			if (typeof currentText === "string") {
				// sometimes the currentText is an object
				itiRef.current.setNumber(currentText); // will autoformat because of formatOnDisplay=true
			}
		}
	}, [dataKey, mui, onChange]);

	useEffect(() => {
		itiRef.current = intlTelInput(inputRef.current, {
			customContainer: "PhoneNumberField-parent",
			initialCountry: defaultValue ? "auto" : countryCode,
			utilsScript: "/script/intl-tel-input-utils.js",
			formatOnDisplay: true,
		});
	}, []); // eslint-disable-line

	useEffect(() => {
		if (!defaultValue) {
			itiRef.current.setCountry(countryCode);
		}
	}, [defaultValue, countryCode]);

	useEffect(() => {
		const input = inputRef.current;
		if (input) {
			input.addEventListener("countrychange", handleChange);
			return () =>
				input.removeEventListener("countrychange", handleChange);
		}
	}, [handleChange]);

	if (mui) {
		return (
			<MuiTextField
				className={classnames("PhoneNumberField", className)}
				defaultValue={defaultValue}
				inputRef={inputRef}
				InputLabelProps={{
					shrink: true,
				}}
				onChange={handleChange}
				{...props}
			/>
		);
	}

	// autoComplete="off" : see https://github.com/jackocnr/intl-tel-input/issues/117
	return (
		<TextField
			className={classnames("PhoneNumberField", className)}
			ref={inputRef}
			dataKey={dataKey}
			onChange={handleChange}
			autoComplete="off"
			{...props}
		/>
	);
}

export default observer(PhoneNumberField);
