import styles from "./Story.module.css";

import {
	Logo,
	TilesContainer,
	Tile,
	IntroductionBullets,
	IntroductionBullet,
	StoryWidget,
} from "./storyCommons";
import DisallowedPermsNotice from "./DisallowedPermsNotice";
import DataCollectionForm from "./DataCollectionForm";

import Button from "../../forms/Button";
import useBodyClass from "../../../utils/useBodyClass";
import * as Backend from "../../../advStoreClient";

import { useState, useRef, useEffect, forwardRef } from "react";
import classnames from "classnames";

export default function Story({
	story,
	transitionAnimation,
	widgetTitle,
	introBullets,
	TilesComponent,
	dataCollectionTitle,
	unexpectedErrorMessage,
	transitionVideoUrl,
	className,
}) {
	useBodyClass(story.id);

	const transitionVideoRef = useRef();
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [missingInfo, setMissingInfo] = useState(null);
	const [disallowedPerms, setDisallowedPerms] = useState(null);

	function playTransitionVideo() {
		if (transitionVideoRef.current) {
			transitionVideoRef.current.currentTime = 0;
			transitionVideoRef.current.play();
		}
	}

	function handleBeginClick() {
		setLoading(true);
		Backend.storyCreateSession({ storyId: story.id })
			.then(({ data }) => {
				if (data.success) {
					if (data.missing && data.missing.length > 0) {
						setMissingInfo(data.missing);
						setStep(1);
					} else {
						playTransitionVideo();
						setStep(2);
					}
				} else {
					if (data.disallowed) {
						setDisallowedPerms(data.disallowed);
					}
					// TODO: error
				}
			})
			.catch(() => {
				// TODO: error
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function handleDataCollectionComplete() {
		// TODO: verify with server no remaining missing info before moving on.
		playTransitionVideo();
		setStep(2);
	}

	function handleStartStory() {
		return Backend.storyStart({ storyId: story.id });
	}

	function handleInteract(interactionType) {
		Backend.storyInteractOnboarding({
			storyId: story.id,
			interactionType,
		});
	}

	return (
		<div className={classnames(styles.story, className)}>
			<TilesContainer
				loading={loading}
				animation={transitionAnimation || "animation2"}
			>
				{transitionVideoUrl ? (
					<TransitionVideo
						ref={transitionVideoRef}
						url={transitionVideoUrl}
					/>
				) : null}
				<Tile stepNumber={0} currentStep={step}>
					<Logo story={story} />
					{disallowedPerms ? (
						<DisallowedPermsNotice
							disallowedPerms={disallowedPerms}
						/>
					) : (
						<>
							<StoryIntroductionBullets bullets={introBullets} />
							<Button onClick={handleBeginClick}>
								{"Got it, let's start!"}
							</Button>
						</>
					)}
				</Tile>
				{missingInfo ? (
					<Tile
						name="form"
						overflow="visible"
						stepNumber={1}
						currentStep={step}
					>
						<DataCollectionForm
							story={story}
							missingInfo={missingInfo}
							collectTitle={dataCollectionTitle}
							unexpectedErrorMessage={unexpectedErrorMessage}
							onComplete={handleDataCollectionComplete}
						/>
					</Tile>
				) : null}
				<TilesComponent
					firstStep={2}
					currentStep={step}
					onSetStep={setStep}
					onStartStory={handleStartStory}
					onInteract={handleInteract}
				/>
			</TilesContainer>
			<StoryWidget story={story} title={widgetTitle || "Hello!"} />
		</div>
	);
}

function StoryIntroductionBullets({ bullets }) {
	return (
		<IntroductionBullets>
			{bullets.map(({ icon, content }, i) => (
				<IntroductionBullet key={i} icon={icon}>
					{content}
				</IntroductionBullet>
			))}
		</IntroductionBullets>
	);
}

const TransitionVideo = forwardRef(({ url }, ref) => {
	const [loaded, setLoaded] = useState(0);

	useEffect(() => {
		const video = ref.current;
		video.controls = false;
		video.load();
		video.play().then(() => {
			video.pause();
			video.currentTime = 0;
			setLoaded(true);
		});
	}, [ref]);

	return (
		<video
			ref={ref}
			src={url}
			className={classnames(
				styles.transitionVideo,
				loaded ? styles.loaded : null
			)}
			preload="auto"
			playsInline
			disableRemotePlayback
			disablePictureInPicture
			muted
		/>
	);
});
