import styles from "./ResendMessage.module.css";

import { useState, useEffect } from "react";
import classnames from "classnames";

export default function ResendMessage({
	text,
	resendText,
	resendInterval,
	className,
	onResend,
}) {
	if (!resendInterval) {
		resendInterval = 60;
	}

	const [resendTimeout, setResendTimeout] = useState(resendInterval);

	useEffect(() => {
		const interval = setInterval(
			() => setResendTimeout((x) => Math.max(0, x - 1)),
			1000
		);
		return () => clearInterval(interval);
	}, []);

	function resendClickHandler() {
		if (resendTimeout === 0) {
			setResendTimeout(resendInterval);
			onResend && onResend();
		}
	}

	return (
		<div className={classnames(styles.resend, className)}>
			{text + " "}
			<span className={styles.resend__action}>
				{resendTimeout > 0 ? (
					`Resend in ${resendTimeout}`
				) : (
					<span
						className={styles.resend__link}
						onClick={resendClickHandler}
					>
						{resendText || "RESEND"}
					</span>
				)}
			</span>
		</div>
	);
}
