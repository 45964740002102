import styles from "./CompleteSignupPage.module.css";
import { useUserStore } from "../stores";
import { THEME_DARK } from "../muiThemes";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Loader as IconLoader } from "lucide-react";

const validationSchema = yup.object({
	first_name: yup
		.string("Enter your first name")
		.required("First name is required"),
	last_name: yup
		.string("Enter your last name")
		.required("Last name is required"),
});

function CompleteSignupPage() {
	const userStore = useUserStore();
	const { completeSignupInfo } = userStore;
	const loading = completeSignupInfo === null;
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		userStore.loadCompleteSignupInfo();
	}, []); // eslint-disable-line

	function handleComplete() {
		setCompleted(true);
		if (window.opener) {
			window.opener.postMessage("signinComplete");
		}
		window.close();
	}

	return (
		<div className={styles.page}>
			<div className={styles.title}>{"Complete signup"}</div>
			{loading ? (
				<Spinner />
			) : (
				<div className={styles.contents}>
					{completed ? (
						<SingupCompletedMessage />
					) : completeSignupInfo.error ? (
						<CompleteSignupError />
					) : (
						<CompleteSignupForm
							info={completeSignupInfo.user}
							onComplete={handleComplete}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default observer(CompleteSignupPage);

function SingupCompletedMessage() {
	return (
		<div>{"Signup completed. You can safely close this window now."}</div>
	);
}

function CompleteSignupError() {
	return <div>{"An unexpected error occurred. Please try again."}</div>;
}

function CompleteSignupForm({ info, onComplete }) {
	const userStore = useUserStore();

	const formik = useFormik({
		initialValues: {
			first_name: info.first_name || "",
			last_name: info.last_name || "",
		},
		validationSchema: validationSchema,
		onSubmit: (values, actions) => {
			userStore
				.completeSignup(values)
				.then(() => {
					onComplete && onComplete();
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		},
	});

	const infoSourceStr = info.integrations.google_login
		? "This information came from Google"
		: info.integrations.facebook_login
		? "This information came from Facebook"
		: null;

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<ThemeProvider theme={THEME_DARK}>
				<TextField
					id="complete-signup-first-name"
					name="first_name"
					label="First name"
					variant="outlined"
					fullWidth
					className={styles.inputField}
					value={formik.values.first_name}
					onChange={formik.handleChange}
					error={
						formik.touched.first_name &&
						Boolean(formik.errors.first_name)
					}
					helperText={
						formik.touched.first_name && formik.errors.first_name
					}
				/>
				<TextField
					id="complete-signup-last-name"
					name="last_name"
					label="Last name"
					variant="outlined"
					fullWidth
					className={styles.inputField}
					value={formik.values.last_name}
					onChange={formik.handleChange}
					error={
						formik.touched.last_name &&
						Boolean(formik.errors.last_name)
					}
					helperText={
						formik.touched.last_name && formik.errors.last_name
					}
				/>
				{info.email ? (
					<TextField
						id="complete-signup-email"
						label="Email"
						variant="outlined"
						fullWidth
						className={styles.inputField}
						type="email"
						value={info.email}
						disabled
					/>
				) : null}
				{infoSourceStr ? (
					<div className={styles.infoSource}>{infoSourceStr}</div>
				) : null}
				<Button
					color="primary"
					variant="contained"
					fullWidth
					className={styles.inputField}
					type="submit"
				>
					{"Finish Sign Up"}
				</Button>
			</ThemeProvider>
		</form>
	);
}

function Spinner() {
	return (
		<div className={styles.spinner}>
			<IconLoader className={styles.spinner__icon} size={30} />
		</div>
	);
}
