import "./colors.css";
import "./index.css";
import AdventureStoreWebsite from "./AdventureStoreWebsite";
import "./fonts/fontello/fontello-embedded.css";
import { UserStore, UserStoreContext } from "./stores/UserStore";
import { UiStore, UiStoreContext } from "./stores/UiStore";

import {
	initAnalytics,
	webVitalsEvent,
	initSentry,
} from "@faintlines/monitoring";

import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import promiseFinally from "promise.prototype.finally";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";

// Needed for Chrome (+ Android WebView) < 63
// https://www.chromestatus.com/feature/5704570235781120
promiseFinally.shim();

initSentry({
	dsn: "https://b08904c990a94323aadd07daac0ca905@o574655.ingest.sentry.io/5727325",
});

const history = createBrowserHistory();

initAnalytics({ history });


const uiStore = new UiStore();

const userStore = new UserStore();
userStore.initialize();

window.addEventListener(
	"message",
	(event) => {
		if (event.origin === window.location.origin) {
			if (event.data === "signinComplete") {
				if (uiStore.postSigninUrl) {
					window.location.href = uiStore.postSigninUrl;
				} else {
					window.location.reload();
				}
			}
		}
	},
	false
);

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<UserStoreContext.Provider value={userStore}>
				<UiStoreContext.Provider value={uiStore}>
					<AdventureStoreWebsite history={history} />
				</UiStoreContext.Provider>
			</UserStoreContext.Provider>
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(webVitalsEvent);
