import styles from "./Header.module.css";
import { useUiStore, useUserStore } from "../stores";

import { Menu as IconMenu, User as IconUser } from "lucide-react";
import { Link } from "react-router-dom";
import useWindowScroll from "react-use/lib/useWindowScroll";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { useState, useRef } from "react";
import { useClickAway } from "react-use";

export default function Header() {
	const scrollY = useWindowScroll().y;

	return (
		<header
			className={classnames(
				styles.header,
				scrollY > 0 ? styles.scrolled : null
			)}
		>
			<Link to="/">
				<h1 className={styles.title}>{"Adventure Store"}</h1>
			</Link>
			<div className={styles.right}>
				<AccountMenuWrapper />
			</div>
		</header>
	);
}

const AccountMenuWrapper = observer(function () {
	const [menuOpen, toggleMenu] = useState(false);

	return (
		<div className={styles.accountMenuWrapper}>
			<div
				className={styles.accountButton}
				onClick={() => toggleMenu(!menuOpen)}
			>
				<IconMenu
					size={18}
					className={classnames(
						styles.accountButton__icon,
						styles.menu
					)}
				/>
				<IconUser
					className={classnames(
						styles.accountButton__icon,
						styles.user
					)}
				/>
			</div>
			{menuOpen ? (
				<AccountMenu onClose={() => toggleMenu(false)} />
			) : null}
		</div>
	);
});

const AccountMenu = observer(function ({ onClose }) {
	const uiStore = useUiStore();
	const userStore = useUserStore();
	const { loggedIn } = useUserStore();
	const ref = useRef(null);
	useClickAway(ref, onClose);

	const signUpClickHandler = () => uiStore.showSignInModal();
	const logoutClickHandler = () => userStore.logout();

	return (
		<div className={styles.accountMenu} ref={ref}>
			{loggedIn ? (
				<>
					<Link
						to="/account"
						className={classnames(
							styles.accountMenu__item,
							styles.primary
						)}
					>
						{"My Account"}
					</Link>
					<div
						className={styles.accountMenu__item}
						onClick={logoutClickHandler}
					>
						{"Sign out"}
					</div>
				</>
			) : (
				<>
					<div
						className={classnames(
							styles.accountMenu__item,
							styles.primary
						)}
						onClick={signUpClickHandler}
					>
						{"Sign up"}
					</div>
					<div
						className={styles.accountMenu__item}
						onClick={signUpClickHandler}
					>
						{"Log in"}
					</div>
				</>
			)}
		</div>
	);
});
