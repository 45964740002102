import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";

export class UiStore {
    signInModalVisible = false;
    signInModalMessage = null;
    postSigninUrl = null;

    constructor() {
        makeAutoObservable(this);
    }

    showSignInModal(message, postSigninUrl) {
        this.signInModalVisible = true;
        this.signInModalMessage = message || null;
        this.postSigninUrl = postSigninUrl || null;
    }

    hideSignInModal() {
        this.signInModalVisible = false;
        this.signInModalMessage = null;
        this.postSigninUrl = null;
    }
}

export const UiStoreContext = createContext(null);
export function useUiStore() {
    return useContext(UiStoreContext);
}
