import styles from "./storyCommons.module.css";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import React, { useState, useEffect } from "react";
import classnames from "classnames";

export function Logo({ story }) {
	return (
		<h1>
			<img className={styles.logo} src={story.logo} alt={story.title} />
		</h1>
	);
}

export function TilesContainer({ children, loading, animation }) {
	return (
		<div
			className={classnames(
				styles.tilesContainer,
				animation ? styles[animation] : null,
				loading ? styles.loading : null
			)}
		>
			{children}
		</div>
	);
}

export function Tile({
	children,
	name,
	stepNumber,
	currentStep,
	fullWidth,
	overflow,
}) {
	const invisible = currentStep < stepNumber;
	const animateIn = stepNumber > 0 && currentStep === stepNumber;
	const animateOut = currentStep > stepNumber;

	const [animatedIn, setAnimatedIn] = useState(false);
	const [shouldAnimateOut, setShouldAnimateOut] = useState(false);

	useEffect(() => {
		if (animateIn) {
			const timeout = setTimeout(() => setAnimatedIn(true), 6000);
			return () => clearTimeout(timeout);
		}
	}, [animateIn]);

	useEffect(() => {
		if (animateOut) {
			const timeout = setTimeout(() => setShouldAnimateOut(true), 1);
			return () => clearTimeout(timeout);
		}
	}, [animateOut]);

	if (invisible && !animateIn && !animateOut) {
		return null;
	}

	return (
		<div
			className={classnames(
				styles.tile,
				name,
				fullWidth ? styles.fullWidth : null,
				invisible ? styles.invisible : null,
				animateIn && !animatedIn ? styles.animateIn : null,
				shouldAnimateOut ? styles.animateOut : null
			)}
		>
			<div className={styles.tile__contents} style={{ overflow }}>
				{children}
			</div>
		</div>
	);
}

export function IntroductionBullets({ children }) {
	return <div className={styles.bullets}>{children}</div>;
}

export function IntroductionBullet({ icon, children }) {
	return (
		<div className={styles.bullets__bullet}>
			<div
				className={classnames(
					styles.bullets__bullet__icon,
					`icon-${icon}`
				)}
			/>
			<div>{children}</div>
		</div>
	);
}

export function StoryWidget({ story, title, hints }) {
	return (
		<Widget
			config={{
				dialogTitle: title,
				hints,
				chat: {
					method: "iframe",
					iframeSrc:
						"https://tawk.to/chat/6087a0775eb20e09cf36e5b8/1f48slkq1",
				},
			}}
			storyConfig={{
				id: story.id,
				assetId: "onboarding",
			}}
		/>
	);
}
