import styles from "./Darknet.module.css";
import Story from "./common/Story";
import { Tile } from "./common/storyCommons";
import ResendMessage from "./common/ResendMessage";
import VideoCall from "./common/VideoCall";

export default function Darknet({ story }) {
	return (
		<Story
			story={story}
			transitionAnimation="animation2"
			introBullets={INTRO_BULLETS}
			TilesComponent={TilesComponent}
			className={styles.story}
		/>
	);
}

function TilesComponent({
	firstStep,
	currentStep,
	onSetStep,
	onStartStory,
	onInteract,
}) {
	function handleVideoCallEnded() {
		onStartStory();
		onSetStep(firstStep + 1);
	}

	function handleResendMessage() {
		onInteract("resend_sms");
	}

	return (
		<>
			<Tile stepNumber={firstStep} fullWidth currentStep={currentStep}>
				<VideoCall
					name="Lucas"
					videoUrl={{
						file: "https://advusercontent.com/darknet/video-calls/lucas-intro3.mp4",
						hls: "https://stream.mux.com/N00VQw6Vc1RtWkYxVYp3Hs01h901i01zj011TymwFFzHYTa8.m3u8",
					}}
					aspectRatio={9 / 16}
					onEnded={handleVideoCallEnded}
				/>
			</Tile>
			<Tile stepNumber={firstStep + 1} currentStep={currentStep}>
				<PostVideoText onResend={handleResendMessage} />
			</Tile>
		</>
	);
}

const INTRO_BULLETS = [
	{
		icon: "globe",
		content:
			"Prepare to revel in an immersive and interactive adventure that all takes place in the real world!",
	},
	{
		icon: "mobile",
		content:
			"To fully rejoice in the adventure, you will be best served by procuring a phone capable of sending and receiving text messages and calls.",
	},
	{
		icon: "megaphone",
		content:
			"Good players, this adventure is still a work in progress, and your feedback is most critical to improving it. Please report to us anything unclear or broken and it will be addressed!",
	},
];

function PostVideoText({ onResend }) {
	return (
		<div className={styles.intro}>
			<p>{"Help Lucas acquire 1000 ShadowCoins and pay the hackers."}</p>
			<p>
				<i>{"Don't forget to check your messages."}</i>
			</p>
			<ResendMessage
				text="Didn't receive a message from Lucas?"
				onResend={onResend}
			/>
		</div>
	);
}
