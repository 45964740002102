import styles from "./StoryFeedbackForm.module.css";
import Button from "./forms/Button";
import { THEME_DARK } from "../muiThemes";
import * as Backend from "../advStoreClient";

import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useState, useRef } from "react";
import { range, sample } from "lodash";
import classnames from "classnames";
import { Check as IconCheck } from "lucide-react";
import { Link } from "react-router-dom";

const FEEDBACK_ADJECTIVES = [
	"lifeline",
	"future",
	"motivation",
	"inspiration",
	"lighthouse",
	"northstar",
	"guiding star",
];

export default function StoryFeedbackForm({ story, endingId, sessionId }) {
	const feedbackAdjective = useRef(sample(FEEDBACK_ADJECTIVES));
	const [activeTile, setActiveTile] = useState(0);

	const [rating, setRating] = useState(null);
	const [text, setText] = useState("");

	const gotoNextTile = () => setActiveTile((x) => x + 1);

	const handleRating = (rating) => {
		setRating(rating);
		gotoNextTile();
	};

	const handleSubmit = () => {
		Backend.storyFeedbackEnding({
			storyId: story.id,
			text,
			rating,
			sessionId,
			endingId,
		});
		gotoNextTile();
	};

	let tileIndex = 0;

	return (
		<ThemeProvider theme={THEME_DARK}>
			<div className={styles.form}>
				<div className={styles.tiles}>
					<QuestionTile
						index={tileIndex++}
						activeTile={activeTile}
						question={`Did you enjoy "${story.title}"?`}
					>
						<Stars
							selectedIndex={rating}
							oneText="It sucks"
							fiveText="Loved it"
							onClick={handleRating}
						/>
					</QuestionTile>
					<QuestionTile
						index={tileIndex++}
						activeTile={activeTile}
						question="Thanks! How can we improve this adventure (optional)?"
					>
						<TextField
							name="text-feedback"
							variant="outlined"
							fullWidth
							multiline
							maxRows={7}
							placeholder="Type your answer here..."
							value={text}
							onChange={(evt) => setText(evt.target.value)}
						/>
						<Button
							className={styles.submitButton}
							onClick={handleSubmit}
						>
							{"Ok"}
							<IconCheck
								className={styles.submitButton__icon}
								size="1em"
							/>
						</Button>
					</QuestionTile>
					<QuestionTile
						index={tileIndex++}
						activeTile={activeTile}
						question={`Thank you! Your feedback is our ${feedbackAdjective.current}!`}
					>
						<Link to="/">
							<Button className={styles.nextButton}>
								{"Play another adventure"}
							</Button>
						</Link>
					</QuestionTile>
				</div>
			</div>
		</ThemeProvider>
	);
}

function QuestionTile({ index, activeTile, question, children }) {
	const entered = index <= activeTile;
	const exited = index < activeTile;

	return (
		<div
			className={classnames(
				styles.tile,
				entered ? styles.entered : null,
				exited ? styles.exited : null
			)}
		>
			<div className={styles.question}>{question}</div>
			<div className={styles.answer}>{children}</div>
		</div>
	);
}

function Stars({ selectedIndex, oneText, fiveText, onClick }) {
	const [hoverIndex, setHoverIndex] = useState(null);

	return (
		<div className={styles.stars}>
			{range(1, 6).map((i) => (
				<Star
					key={i}
					index={i}
					text={i === 1 ? oneText : i === 5 ? fiveText : null}
					selected={selectedIndex !== null && selectedIndex >= i}
					hovered={hoverIndex !== null && hoverIndex >= i}
					onHoverChange={setHoverIndex}
					onClick={onClick}
				/>
			))}
		</div>
	);
}

const Star = ({ index, text, selected, hovered, onClick, onHoverChange }) => (
	<div
		className={classnames(
			styles.star,
			selected ? styles.selected : null,
			hovered ? styles.hovered : null
		)}
		onMouseOver={() => onHoverChange(index)}
		onMouseLeave={() => onHoverChange(null)}
		onClick={() => onClick(index)}
	>
		<StarIcon size={60} />
		{text ? <div className={styles.star__text}>{text}</div> : null}
	</div>
);

const StarIcon = ({ size }) => (
	<svg
		className={styles.star__icon}
		width={size}
		height={size}
		viewBox="0 0 24 24"
		stroke="currentColor"
		strokeWidth="1"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
	</svg>
);
StarIcon.defaultProps = {
	size: 24,
};
