import BrowsePage from "./pages/BrowsePage";
import PlayPage from "./pages/PlayPage";
import EndingPage from "./pages/EndingPage";
import UserAccountPage from "./pages/UserAccountPage";
import CompleteSignupPage from "./pages/CompleteSignupPage";
import SigninCompletePage from "./pages/SigninCompletePage";
import WidgetPage from "./pages/WidgetPage";
import StoryModal from "./components/StoryModal";
import SignInModal from "./components/modals/SignInModal";
import { FEATURED_STORY, STORY_SECTIONS, ALL_STORIES_BY_ID } from "./stories";

import { Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function App({ history }) {
    return (
        <div className="App">
            <Helmet>
                <title>{`Adventure Store`}</title>
            </Helmet>
            <Router history={history}>
                <Switch>
                    <Route
                        path="/play/:storyId"
                        exact
                        render={(routeProps) => (
                            <PlayPage
                                story={
                                    ALL_STORIES_BY_ID[
                                        routeProps.match.params.storyId
                                    ]
                                }
                            />
                        )}
                    />
                    <Route
                        path="/ending/:storyId/:endingId"
                        exact
                        render={(routeProps) => (
                            <EndingPage
                                story={
                                    ALL_STORIES_BY_ID[
                                        routeProps.match.params.storyId
                                    ]
                                }
                                endingId={routeProps.match.params.endingId}
                            />
                        )}
                    />
                    <Route
                        path={["/", "/story/:storyId"]}
                        exact
                        render={({ match }) => {
                            const storyId = match?.params?.storyId;
                            return (
                                <>
                                    <BrowsePage
                                        storyId={storyId}
                                        featuredStory={FEATURED_STORY}
                                        storySections={STORY_SECTIONS}
                                    />
                                    <StoryModal
                                        storyId={storyId}
                                        storiesById={ALL_STORIES_BY_ID}
                                    />
                                </>
                            );
                        }}
                    />
                    <Route path="/complete-signup" exact>
                        <CompleteSignupPage />
                    </Route>
                    <Route path="/logged-in" exact>
                        <SigninCompletePage />
                    </Route>
                    <Route path="/account" exact>
                        <UserAccountPage />
                    </Route>
                    <Route path="/widget" exact>
                        <WidgetPage />
                    </Route>
                </Switch>
            </Router>
            <SignInModal />
        </div>
    );
}
