import * as Backend from "../advStoreClient";
import { getBaseUrl } from "@faintlines/backend-client";
import { centeredPopup } from "../utils/browserUtils";

import { makeAutoObservable, autorun, toJS, runInAction } from "mobx";
import { createContext, useContext } from "react";
import store from "store";
import urljoin from "url-join";

const PERSIST_STORE_KEY = "state";
const PERSIST_KEYS = ["user", "ipInfo"];

const DEFAULT_COUNTRY_CODE = "us";

export class UserStore {
    userLoaded = false;
    completeSignupInfo = null;
    ipInfo = null;
    user = null;

    constructor() {
        const existingState = store.get(PERSIST_STORE_KEY);
        if (existingState) {
            PERSIST_KEYS.forEach((key) => {
                if (existingState[key]) {
                    this[key] = existingState[key];
                }
            });
        }

        makeAutoObservable(this);

        autorun(() => {
            const persistedState = {};
            PERSIST_KEYS.forEach(
                (key) => (persistedState[key] = toJS(this[key]))
            );
            store.set(PERSIST_STORE_KEY, persistedState);
        });
    }

    get loggedIn() {
        return !!this.user;
    }

    get countryCode() {
        return this.ipInfo?.country_code || DEFAULT_COUNTRY_CODE;
    }

    initialize() {
        this.getUserInfo();
        this.getIpInfo();
    }

    getUserInfo() {
        Backend.getUserInfo()
            .then(({ data }) => {
                runInAction(() => {
                    this.user = data.user;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.user = null;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.userLoaded = true;
                });
            });
    }

    getIpInfo() {
        Backend.getIpInfo()
            .then(({ data }) => {
                runInAction(() => {
                    this.ipInfo = data;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.ipInfo = null;
                });
            });
    }

    loadCompleteSignupInfo() {
        Backend.getCompleteSignupInfo()
            .then(({ data }) => {
                runInAction(() => {
                    this.completeSignupInfo = data;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.completeSignupInfo = { error: true };
                });
            });
    }

    completeSignup(userData) {
        return Backend.completeSignup(userData);
    }

    loginWithGoogle(options) {
        loginWith("Google", "google", options);
    }

    loginWithFacebook(options) {
        loginWith("Facebook", "facebook", options);
    }

    logout() {
        Backend.logout().finally(() => {
            this.user = null;
            window.location.reload();
        });
    }

    updateUserName({ firstName, lastName }) {
        return new Promise((resolve, reject) => {
            Backend.userUpdateName(firstName, lastName)
                .then(({ data }) => {
                    runInAction(() => {
                        this.user = data.user;
                        resolve();
                    });
                })
                .catch(() => {
                    runInAction(() => {
                        reject();
                    });
                });
        });
    }

    updateUserPhone({ otp, verify, phone }) {
        return new Promise((resolve, reject) => {
            Backend.userUpdatePhone({ otp, verify, phone })
                .then(({ data }) => {
                    if (data.user) {
                        runInAction(() => {
                            this.user = data.user;
                            resolve({ success: true });
                        });
                    } else {
                        resolve({ error: data.error });
                    }
                })
                .catch(() => {
                    runInAction(() => {
                        reject();
                    });
                });
        });
    }

    updateUserPreference(key, value) {
        return new Promise((resolve, reject) => {
            const oldValue = this.user.prefs[key];
            this.user.prefs[key] = value;
            Backend.userUpdatePreference(key, value)
                .then(resolve)
                .catch(() => {
                    runInAction(() => {
                        this.user.prefs[key] = oldValue;
                        reject();
                    });
                });
        });
    }
}

function loginWith(serviceName, serviceSlug, options) {
    options = options || {};
    const nextUrl = encodeURIComponent(options.nextUrl || "");
    const loginUrl = urljoin(
        getBaseUrl(),
        `/api/auth/${serviceSlug}/login?next=${nextUrl}`
    );
    centeredPopup({
        url: loginUrl,
        title: `Continue with ${serviceName}`,
        w: 500,
        h: 599,
        options:
            "resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no",
    });
}

export const UserStoreContext = createContext(null);
export function useUserStore() {
    return useContext(UserStoreContext);
}
