import styles from "./IntroVideo.module.css";

import VideoPlayer from "@faintlines/video-player";

import React, { useRef } from "react";
import classnames from "classnames";

// TODOs:
// - optional play/pause controls
// - optional skip button

export default function IntroVideo({
	url,
	skipText,
	fullScreen,
	autoPlay,
	onEnded,
}) {
	const videoRef = useRef(null);

	const handleDoubleClick = () => {
		if (skipText && videoRef.current) {
			videoRef.current.seekTo(videoRef.current.duration - 1);
		}
	};

	return (
		<div
			className={classnames(
				styles.wrapper,
				fullScreen ? styles.fullScreen : null
			)}
			onDoubleClick={handleDoubleClick}
		>
			<VideoPlayer
				ref={videoRef}
				className={styles.video}
				url={url}
				width="100%"
				height={fullScreen ? "100%" : "auto"}
				autoPlay={autoPlay}
				onEnded={onEnded}
			/>
		</div>
	);
}
IntroVideo.defaultProps = {
	fullScreen: true,
};
