import styles from "./StoryStartButtons.module.css";
import { useUserStore, useUiStore } from "../../stores";

import { Loader as IconLoader } from "lucide-react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import classnames from "classnames";

export const StoryPlayButton = observer(function ({ story, featured }) {
	const userStore = useUserStore();
	const uiStore = useUiStore();
	const { userLoaded } = userStore;

	function clickHandler(evt) {
		if (!userLoaded) {
			evt.preventDefault();
			return;
		}

		if (!userStore.loggedIn) {
			evt.preventDefault();
			uiStore.showSignInModal(
				`To play ${story.title}, please sign in or create an account.`,
				`/play/${story.id}`
			);
		}
	}

	return (
		<Link
			to={`/play/${story.id}`}
			className={classnames(
				styles.button,
				featured ? styles.featured : null
			)}
			onClick={clickHandler}
		>
			{userLoaded ? "Play" : <IconLoader className={styles.spinner} />}
		</Link>
	);
});

export const StoryDownloadButton = ({ url, featured }) => (
	<a
		className={classnames(styles.button, featured ? styles.featured : null)}
		href={url}
		target="_blank"
		rel="noopener noreferrer"
	>
		{"Download"}
	</a>
);
