/* eslint-disable global-require */

// TODO: load from server

const STAGING_ONLY = process.env.REACT_APP_ENVIRONMENT !== "production";
// const DEV_ONLY = process.env.NODE_ENV === "development";

const wizards = {
    id: "wizards",
    title: "Saving The Great Wizard",
    available: true,
    logo: require("./images/stories/wizards/logo.png").default,
    video: require("./images/stories/wizards/video.mp4").default,
    poster: "https://advusercontent.com/wizards/poster2.jpg",
    posterNoLogo: "https://advusercontent.com/wizards/poster2-no-logo.jpg",
    description:
        "Cross the threshold into a magical realm and become a WIZARD!",
    endings: {
        "ac471bfe-aa57-4b65-a3fe-8b57a2ca69ef": {
            title: "YOU DID IT!",
            text: "You managed to switch the potions and prevent Plott from killing the Great Wizard. Job well done!",
        },
    },
};

const darknet = {
    id: "darknet",
    title: "Darknet",
    available: true,
    logo: require("./images/stories/darknet/logo.png").default,
    poster: "https://advusercontent.com/darknet/poster1.jpg",
    posterNoLogo: "https://advusercontent.com/darknet/poster1-no-logo.jpg",
    description: "How far would you go to help your best friend?",
    endings: {
        "8a0f02c2-90df-4953-b903-698d20d90df4": {
            title: "YOU DID IT!!",
            text: "Your willingness to help Lucas was the key to passing his friendship test...and you even proved you've got game on the Darknet!",
        },
        "f5e77ce6-74b6-46cf-ad98-273af4055f2f": {
            title: "YOU DID IT!!",
            text: "Your hacking skills and moral compass pointed you in the right direction! You passed Lucas's trust test and made some new friends at the FBI. Job well done!",
        },
    },
};

const museum = {
    id: "museum",
    title: "The Artist's Heart",
    available: true,
    logo: "https://advusercontent.com/museum/logo3.png",
    // video: require("./images/stories/wizards/video.mp4").default,
    poster: "https://advusercontent.com/museum/poster2b.jpg",
    posterNoLogo: "https://advusercontent.com/museum/poster2-no-logo.jpg",
    thumbnail: "https://advusercontent.com/museum/thumb1.jpg",
    description: "Go undercover at a museum to catch a slippery art thief.",
    descriptionLong:
        "A heist has left two paintings missing from the famous Bellin Museum. Rumors are circulating that the theft was an inside job, and it's up to you to pose as a museum security guard and find the thief before the paintings are lost in the art market!",
    endings: {
        "b50eeae4-9995-4bc9-b80a-af7b35258c43": {
            title: "YOU DID IT!!",
            text: "Thanks to you, the FBI were able to arrest the gang that robbed the museum. You made uncle Jimmy so proud!",
        },
    },
};

const anchor = {
    id: "the-anchor",
    title: "The Anchor",
    available: true,
    logo: "https://advusercontent.com/anchor/logo.png",
    // video: require("./images/stories/wizards/video.mp4").default,
    poster: "https://advusercontent.com/anchor/poster1.jpg",
    posterNoLogo: "https://advusercontent.com/anchor/poster1-no-logo.jpg",
    thumbnail: "https://advusercontent.com/anchor/thumb1.jpg",
    description:
        "Put your investigation skills to the test and track down a murderer.",
    descriptionLong:
        "A woman has washed up on the shore of a local lake, and the police force is scrambling to find the murderer. When her squad comes up empty, a determined detective begrudgingly turns to you for help. Can you solve the murder?",
    endings: {
        "03f44836-3adf-46e0-a0d1-ddbfee8e89a9": {
            title: "YOU DID IT!!",
            text: "Thanks to you, the police arrested Martha and she confessed. Great job!",
        },
    },
};

const peekAPhone = {
    id: "peek-a-phone",
    title: "Peek a Phone",
    available: true,
    poster:
        "https://advusercontent.com/adventure-store/peek-a-phone-cover-1.jpg",
    thumbnail:
        "https://advusercontent.com/adventure-store/peek-a-phone-cover-1.jpg",
    description:
        "Sharpen your detective skills and gather clues in this realistic mystery game.",
    descriptionLong:
        "Peek a Phone is a realistic mystery adventure game where you'll dive into an immersive story that sharpens your detective skills. Unravel the mystery, find the vital clue, solve the puzzle, and enter into realistic games!",
    downloadUrl: "https://peekaphonegame.com/",
};

const twins = {
    id: "twins",
    title: "Twins",
    available: true,
    pilot: true,
    logo: "https://advusercontent.com/twins/logo.png",
    // video: require("./images/stories/wizards/video.mp4").default,
    poster: "https://advusercontent.com/twins/poster2.jpg",
    posterNoLogo: "https://advusercontent.com/twins/poster2-no-logo.jpg",
    thumbnail: "https://advusercontent.com/twins/thumb2.jpg",
    description: "Help an old friend save two siblings...from themselves!",
    descriptionLong:
        "Two fraternal twins hatch a criminal plan to get their younger brother out of a jam. The concerned wife of one of the siblings reaches out to an old friend for help...that's where you come in. Can you throw a wrench in the works before everyone ends up in joint?",
    endings: {
        "75501c5e-a09f-42eb-8981-d7ad1831690e": {
            type: "restart",
            title: "THERE'S STILL HOPE",
            text: "You failed helping Eva, but you still have a chance of saving the day!\nGo back to the moment before you failed, and choose a different path.",
            nextText: "Try Again",
            nextInteraction: "restart",
        },
        "9f8cf9ff-6693-4688-a361-b45faca1ccb8": {
            title: "YOU DID IT!!",
            text: "You cleverly sabotaged the robbery without making the twins suspect you. Job well done!",
        },
    },
};

const theLostTomb = {
    id: "the-lost-tomb",
    title: "The Lost Tomb",
    available: STAGING_ONLY,
    pilot: true,
    logo: "https://advusercontent.com/the-lost-tomb/logo.png",
    poster: "https://advusercontent.com/the-lost-tomb/poster1.jpg",
    posterNoLogo:
        "https://advusercontent.com/the-lost-tomb/poster1-no-logo.jpg",
    thumbnail: "https://advusercontent.com/the-lost-tomb/thumb1.jpg",
    description: "Find a missing professor.",
    // descriptionLong:
    //  "A woman has washed up on the shore of a local lake, and the police force is scrambling to find the murderer. When her squad comes up empty, a determined detective begrudgingly turns to you for help. Can you solve the murder?",
};

const phoenix = {
    id: "phoenix",
    title: "Phoenix",
    available: STAGING_ONLY,
    pilot: true,
    logo: "Help aunty Virginia",
    poster: "https://advusercontent.com/phoenix/poster1.jpg",
    posterNoLogo: "https://advusercontent.com/phoenix/poster1-no-logo.jpg",
    thumbnail: "https://advusercontent.com/phoenix/thumb1.jpg",
    description: "...",
    // descriptionLong:
    //  "A woman has washed up on the shore of a local lake, and the police force is scrambling to find the murderer. When her squad comes up empty, a determined detective begrudgingly turns to you for help. Can you solve the murder?",
};

const crimeOfPassion = {
    id: "crime-of-passion",
    title: "Crime Of Passion",
    available: true,
    pilot: true,
    logo: "https://advusercontent.com/crime-of-passion/logo.png",
    poster: "https://advusercontent.com/crime-of-passion/poster.jpg",
    posterNoLogo:
        "https://advusercontent.com/crime-of-passion/poster-no-logo.jpg",
    thumbnail: "https://advusercontent.com/crime-of-passion/thumb.jpg",
    description: "Queen V needs you to save Oakwood town dignity",
    descriptionLong:
        "Virginia Valentine is the queen of Oakwood’s music scene. Or was until the new girl, Margaret Hill, took all the best shifts at the Blind Tiger Tavern. Virginia’s sure something is going on between Margaret and the Blind Tiger’s manager. Can you find evidence of the scandal and help Virginia reclaim her throne?",
    endings: {
        "125703cf-9a6d-4338-9c62-bedfd6cc0315": {
            type: "restart",
            title: "THERE'S STILL HOPE",
            text: "Virginia's honor is doomed, and you couldn't help her. But wait, you still have a chance.\nGo back to the moment before you failed, and choose a different path.",
            nextText: "Try Again",
            nextInteraction: "restart",
        },
        "cfae49c0-5660-4aca-bce1-5c6aabd63efd": {
            type: "restart",
            title: "THERE'S STILL HOPE",
            text: "You betrayed charlotte's trust, but unlike her fiance you get a second chance.",
            nextText: "Try Again",
            nextInteraction: "restart",
        },
        "218815df-4fac-487f-b203-e5e0c6c97303": {
            type: "restart",
            title: "THERE'S STILL HOPE",
            text: "Infront of everyone? really?! but here is a magic clock that will clear everyones memory. use it.",
            nextText: "Try Again",
            nextInteraction: "restart",
        },
        "dce9231d-9979-4782-9eee-0d5dbf4ff4ee": {
            type: "restart",
            title: "THERE'S STILL HOPE",
            text: "Alfonso is a hard nut to crack.",
            nextText: "Try Again",
            nextInteraction: "restart",
        },
        "15623788-3c76-4571-bfef-f318aa1ee053": {
            type: "restart",
            title: "THERE'S STILL HOPE",
            text: "So close but so far. but hey, we developed this loopback just for you.",
            nextText: "Try Again",
            nextInteraction: "restart",
        },
        "31f899e9-1293-4abf-940c-ab9881d9c03c": {
            title: "YOU DID IT!!",
            text: "You managed to save the queen, and the people of Oakwood. The town is forever in your debt",
        },
    },
};

export const FEATURED_STORY = darknet;

export const STORY_SECTIONS = [
    {
        title: "New Releases",
        stories: filterStories([
            darknet,
            museum,
            anchor,
            twins,
            wizards,
            theLostTomb,
            phoenix,
            crimeOfPassion,
        ]),
    },
    {
        title: "Downloadable Games",
        stories: filterStories([peekAPhone]),
    },
];

export const ALL_STORIES = filterStories([
    wizards,
    darknet,
    museum,
    anchor,
    twins,
    theLostTomb,
    phoenix,
    crimeOfPassion,
    peekAPhone,
]);

export const ALL_STORIES_BY_ID = {};
ALL_STORIES.forEach((x) => {
    ALL_STORIES_BY_ID[x.id] = x;
});

function filterStories(stories) {
    return stories.filter((x) => x.available);
}
