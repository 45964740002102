import styles from "./UserAccountPage.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PhoneNumberField from "../components/forms/PhoneNumberField";
import useBodyClass from "../utils/useBodyClass";
import { useUserStore } from "../stores/UserStore";
import { THEME_LIGHT } from "../muiThemes";
import * as Backend from "../advStoreClient";

import { Loader as IconLoader } from "lucide-react";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { ThemeProvider } from "@material-ui/core/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import classnames from "classnames";

const DEFAULT_ERROR_MSG =
	"An unexpected error occurred. Please try again or contact support.";

export default function UserAccountPage() {
	useBodyClass("light");

	return (
		<ThemeProvider theme={THEME_LIGHT}>
			<div className={styles.page}>
				<Header />
				<Container maxWidth="md">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h4" component="h2">
								{"Account"}
							</Typography>
						</Grid>
						<SectionDivider />
						<UserAccountInfo />
					</Grid>
				</Container>
				<Footer />
			</div>
		</ThemeProvider>
	);
}

const UserAccountInfo = observer(() => {
	const { userLoaded, user } = useUserStore();

	if (!userLoaded) {
		return <Spinner />;
	}

	if (!user) {
		return <Redirect to="/" />;
	}

	return (
		<>
			<Section title="PERSONAL INFO">
				<PersonalInfo />
			</Section>
			<SectionDivider />
			<Section title="PREFERENCES">
				<PreferencesDetails />
			</Section>
		</>
	);
});

function SectionDivider() {
	return (
		<Grid item xs={12}>
			<Divider />
		</Grid>
	);
}

function Section({ title, children }) {
	return (
		<>
			<Grid item lg={4} xs={12}>
				<Typography variant="h6" component="h3">
					{title}
				</Typography>
			</Grid>
			<Grid item lg={8} xs={12}>
				{children}
			</Grid>
		</>
	);
}

const PersonalInfo = observer(() => {
	const userStore = useUserStore();
	const { user } = userStore;
	const [editing, setEditing] = useState(null);

	const props = {
		editing,
		onEditStart: (name) => setEditing(name),
		onEditEnd: () => setEditing(null),
	};

	return (
		<List
			component="nav"
			aria-label="main mailbox folders"
			disablePadding
			dense
		>
			<PersonalInfoItem
				id="name"
				title="Name"
				value={`${user.first_name} ${user.last_name}`}
				EditComponent={NameEditForm}
				first
				{...props}
			/>
			<PersonalInfoItem
				id="email"
				title="Email address"
				value={user.email}
				{...props}
			/>
			<PersonalInfoItem
				id="phone"
				title="Phone number"
				value={user.phone_national}
				EditComponent={PhoneEditForm}
				last
				{...props}
			/>
		</List>
	);
});

function PersonalInfoItem({
	id,
	title,
	value,
	first,
	last,
	EditComponent,
	editing,
	onEditStart,
	onEditEnd,
}) {
	const disabled = editing && editing !== id;

	return (
		<ListItem
			className={classnames(
				styles.personalInfoItem,
				disabled ? styles.disabled : null
			)}
			disableGutters
			divider={!last}
		>
			<Box
				mt={first ? 0 : 2}
				mb={last ? 0 : 2}
				style={{ width: "100%", position: "relative" }}
			>
				<Typography
					variant="body1"
					component="h3"
					style={{ fontWeight: 600 }}
				>
					{title}
				</Typography>
				{editing === id ? (
					<>
						<EditComponent onClose={onEditEnd} />
						<Link
							className={styles.editLink}
							component="div"
							onClick={onEditEnd}
						>
							{"Cancel"}
						</Link>
					</>
				) : (
					<>
						<Typography
							variant="body1"
							style={{
								marginTop: "0.25em",
								opacity: value ? 1 : 0.7,
							}}
						>
							{value || "Not provided"}
						</Typography>
						{EditComponent ? (
							<Link
								className={styles.editLink}
								component="div"
								onClick={() => onEditStart(id)}
							>
								{"Edit"}
							</Link>
						) : null}
					</>
				)}
			</Box>
		</ListItem>
	);
}

function PreferencesDetails() {
	return (
		<Grid container spacing={2}>
			<TogglePreference
				prefKey="allow_incoming_sms"
				label="Allow receiving text messages (SMS) from adventures"
			/>
			<TogglePreference
				prefKey="allow_incoming_calls"
				label="Allow receiving phone calls from adventures"
			/>
			<TogglePreference
				prefKey="allow_incoming_emails"
				label="Allow receiving emails from adventures"
			/>
			<TogglePreference
				prefKey="allow_marketing_emails"
				label="Subscribe for marketing emails from the Adventure Store"
			/>
		</Grid>
	);
}

function TogglePreference({ label, prefKey }) {
	return (
		<Item
			left={label}
			leftSize={9}
			right={<UserPreferenceToggle prefKey={prefKey} label={label} />}
		/>
	);
}

const UserPreferenceToggle = observer(({ prefKey, label }) => {
	const userStore = useUserStore();
	const { user } = userStore;

	const checked = !!user.prefs[prefKey];

	function changeHandler(event) {
		userStore.updateUserPreference(prefKey, event.target.checked);
	}

	return (
		<Switch
			checked={checked}
			onChange={changeHandler}
			inputProps={{ "aria-label": label }}
		/>
	);
});

function Item({ left, right, leftSize }) {
	leftSize = leftSize || 6;
	return (
		<>
			<Grid item xs={leftSize}>
				{left}
			</Grid>
			<Grid item xs={12 - leftSize}>
				{right}
			</Grid>
		</>
	);
}

function Spinner() {
	return (
		<div className={styles.spinner}>
			<IconLoader className={styles.spinner__icon} size={50} />
		</div>
	);
}

function NameEditForm({ onClose }) {
	const userStore = useUserStore();
	const { user } = userStore;

	const formik = useFormik({
		initialValues: {
			firstName: user.first_name || "",
			lastName: user.last_name || "",
		},
		validationSchema: yup.object({
			firstName: yup
				.string("First name")
				.required("First name is required"),
			lastName: yup.string("Last name").required("Last name is required"),
		}),
		onSubmit: (values, actions) => {
			userStore
				.updateUserName(values)
				.then(() => {
					onClose();
				})
				.catch(() => {
					// TODO: error
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		},
	});

	return (
		<EditForm onSubmit={formik.handleSubmit}>
			<Grid container>
				<Grid item xs={12} lg={6}>
					<TextField
						id="account-first-name"
						name="firstName"
						label="First name"
						variant="outlined"
						size="small"
						value={formik.values.firstName}
						onChange={formik.handleChange}
						error={
							formik.touched.firstName &&
							Boolean(formik.errors.firstName)
						}
						helperText={
							formik.touched.firstName && formik.errors.firstName
						}
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<TextField
						id="account-last-name"
						name="lastName"
						label="Last name"
						variant="outlined"
						size="small"
						style={{ marginRight: 10 }}
						value={formik.values.lastName}
						onChange={formik.handleChange}
						error={
							formik.touched.lastName &&
							Boolean(formik.errors.lastName)
						}
						helperText={
							formik.touched.lastName && formik.errors.lastName
						}
					/>
				</Grid>
			</Grid>
			<EditSubmitButton submitting={formik.isSubmitting} />
		</EditForm>
	);
}

function PhoneEditForm({ onClose }) {
	const userStore = useUserStore();
	const [verificationData, setVerificationData] = useState(null);

	console.log("verificationData", verificationData);

	if (!verificationData) {
		return (
			<PhoneVerificationForm
				onRequestSent={(data) => setVerificationData(data)}
			/>
		);
	}

	function handleSubmit({ otp }) {
		return userStore.updateUserPhone({ otp, ...verificationData });
	}

	return (
		<OtpForm
			title={`Enter the verification code sent to ${verificationData.sent_to}.`}
			onSubmit={handleSubmit}
			onClose={onClose}
		/>
	);
}

function PhoneVerificationForm({ onRequestSent }) {
	const userStore = useUserStore();
	const { user } = userStore;

	const formik = useFormik({
		initialValues: {
			phone: user.phone || "",
		},
		validationSchema: yup.object({
			phone: yup.string("Phone number").required("Phone is required"),
		}),
		onSubmit: (values, actions) => {
			Backend.userVerifyPhone(values)
				.then(({ data }) => {
					if (data.success) {
						onRequestSent({
							verify: data.verify,
							sent_to: data.sent_to,
							phone: values.phone,
						});
					} else {
						// TODO: error
					}
				})
				.catch(() => {
					// TODO: error
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		},
	});

	return (
		<EditForm onSubmit={formik.handleSubmit}>
			<PhoneNumberField
				mui
				id="account-phone"
				name="phone"
				label="Phone number"
				variant="outlined"
				type="tel"
				defaultValue={user.phone}
				onChange={(value) => formik.setFieldValue("phone", value)}
				error={formik.touched.phone && Boolean(formik.errors.phone)}
				helperText={formik.touched.phone && formik.errors.phone}
			/>
			<EditSubmitButton text="Next" submitting={formik.isSubmitting} />
		</EditForm>
	);
}

function OtpForm({ title, onSubmit, onClose }) {
	const [error, setError] = useState(null);

	const formik = useFormik({
		initialValues: {
			otp: "",
		},
		validationSchema: yup.object({
			otp: yup
				.number("Verification code")
				.required("Verification code is required"),
		}),
		onSubmit: (values, actions) => {
			setError(null);
			onSubmit(values)
				.then(({ success, error }) => {
					if (success) {
						onClose();
					} else {
						setError(error || DEFAULT_ERROR_MSG);
					}
				})
				.catch(() => {
					setError(DEFAULT_ERROR_MSG);
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		},
	});

	return (
		<EditForm title={title} error={error} onSubmit={formik.handleSubmit}>
			<TextField
				id="account-otp"
				name="otp"
				label="Verification code"
				variant="outlined"
				size="small"
				value={formik.values.lastName}
				onChange={formik.handleChange}
				error={
					formik.touched.lastName && Boolean(formik.errors.lastName)
				}
				helperText={formik.touched.lastName && formik.errors.lastName}
			/>
			<EditSubmitButton text="Verify" submitting={formik.isSubmitting} />
		</EditForm>
	);
}

function EditForm({ title, error, children, onSubmit }) {
	return (
		<form className={styles.editForm} onSubmit={onSubmit} noValidate>
			{title ? (
				<Box mb={2}>
					<Typography variant="subtitle1">{title}</Typography>
				</Box>
			) : null}
			{children}
			{error ? (
				<Box mt={2}>
					<Typography
						variant="subtitle1"
						className={styles.editForm__error}
					>
						{error}
					</Typography>
				</Box>
			) : null}
		</form>
	);
}

function EditSubmitButton({ text, submitting }) {
	return (
		<Box mt={2}>
			<Button
				color="primary"
				variant="contained"
				type="submit"
				disabled={submitting}
			>
				{submitting ? (
					<IconLoader
						className={styles.submitButtonSpinner}
						size={18}
					/>
				) : (
					text || "Save"
				)}
			</Button>
		</Box>
	);
}
