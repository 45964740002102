import styles from "./WidgetContents.module.css";

import { storyFeedback, storyInteract } from "@faintlines/backend-client";

import React, { useState, useEffect, createContext, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import { Loader as IconLoader } from "lucide-react";
import useMeasure from "react-use/lib/useMeasure";

export const WidgetContext = createContext({});
export function useWidgetContext() {
	return useContext(WidgetContext);
}

export default function WidgetContents({ config, context, onHeightChanged }) {
	const { chat, story } = config;
	const [tile, setTile] = useState("menu");
	const [ref, widgetSize] = useMeasure();

	useEffect(
		() => onHeightChanged(widgetSize.height),
		[widgetSize.height, onHeightChanged]
	);

	function handleAction(action) {
		setTile(action);
	}

	function handleScreenClose() {
		setTile("menu");
	}

	return (
		<div
			className={classnames(
				styles.dialog__contents,
				styles[`tile_${tile}`]
			)}
			ref={ref}
		>
			<WidgetContext.Provider value={{ config, context }}>
				<DialogTile visible={tile === "menu"}>
					<Menu onAction={handleAction} />
				</DialogTile>
				<DialogTile visible={tile === "chat"} alwaysRender>
					<LiveChat {...chat} onClose={handleScreenClose} />
				</DialogTile>
				<DialogTile visible={tile === "feedback"}>
					<FeedbackForm story={story} onClose={handleScreenClose} />
				</DialogTile>
				<DialogTile visible={tile === "hints"}>
					<Hints story={story} onClose={handleScreenClose} />
				</DialogTile>
			</WidgetContext.Provider>
		</div>
	);
}

function DialogTile({ visible, children, alwaysRender }) {
	if (!visible && !alwaysRender) {
		return null;
	}

	return (
		<div
			className={classnames(
				styles.dialog__tile,
				visible ? styles.visible : null
			)}
		>
			{children}
		</div>
	);
}

function Menu({ visible, onAction }) {
	const { config } = useWidgetContext();
	const { dialogTitle, chat, story } = config;

	const haveStory = !!(story && story.id && story.assetId);

	return (
		<div className={styles.menu}>
			{dialogTitle ? (
				<div className={styles.menu__title}>{dialogTitle}</div>
			) : null}
			{haveStory ? (
				<MenuButton
					text="I need a hint!"
					onClick={() => onAction("hints")}
				/>
			) : null}
			<MenuButton
				text={"Leave feedback"}
				onClick={() => onAction("feedback")}
			/>
			{chat ? (
				<MenuButton
					text={chat.buttonText || "Chat with a live person"}
					onClick={() => onAction("chat")}
				/>
			) : null}
		</div>
	);
}

function MenuButton({ text, ...props }) {
	return (
		<Button spaceTop {...props}>
			{text}
		</Button>
	);
}

function FeedbackForm({ story, onClose }) {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const formik = useFormik({
		initialValues: {
			text: "",
		},
		validationSchema: yup.object({
			text: yup.string("").required("Feedback is required"),
		}),
		enableReinitialize: true,
		onSubmit: (values, actions) => {
			setError(false);
			storyFeedback({
				storyId: story.id,
				assetId: story.assetId,
				sessionId: story.sessionId,
				...values,
			})
				.then(() => {
					setSuccess(true);
					formik.resetForm();
				})
				.catch(() => setError(true))
				.finally(() => {
					actions.setSubmitting(false);
				});
		},
	});

	function handleClose() {
		onClose();
		setSuccess(false);
		setError(false);
	}

	return (
		<div
			className={classnames(
				styles.form,
				formik.isSubmitting ? styles.submitting : null
			)}
		>
			{success ? (
				<div className={styles.form__success}>{"Thank you!"}</div>
			) : (
				<>
					<div className={styles.form__title}>
						{"We're eager to hear your feedback!"}
					</div>
					<div className={styles.form__subtitle}>
						{"Please be as descriptive as possible."}
					</div>
					<form
						onSubmit={formik.handleSubmit}
						noValidate
						className={styles.form__form}
					>
						<TextField
							id="widget-feedback-text"
							name="text"
							placeholder="Type your feedback here..."
							fullWidth
							multiline
							rowsMax={3}
							className={styles.form__input}
							value={formik.values.text}
							onChange={formik.handleChange}
							error={
								formik.touched.text &&
								Boolean(formik.errors.text)
							}
							helperText={
								formik.touched.text && formik.errors.text
							}
						/>
						<Button
							spaceTop
							className={styles.form__button}
							loading={formik.isSubmitting}
							type="submit"
						>
							{"Send Feedback"}
						</Button>
						{error ? (
							<div className={styles.form__error}>
								{"Failed sending feedback, please try again."}
							</div>
						) : null}
					</form>
				</>
			)}
			<BackLink onClick={handleClose} />
		</div>
	);
}

function Hints({ story, onClose }) {
	const { context } = useWidgetContext();
	const [loading, setLoading] = useState(true);
	const [hints, setHints] = useState(null);

	useEffect(() => {
		setLoading(true);
		setHints(null);

		storyInteract({
			interactionType: "get_hints",
			storyId: story.id,
			assetId: story.assetId,
			sessionId: story.sessionId,
			data: { context },
		})
			.then(({ data }) => {
				setHints(data.hints || []);
			})
			.catch(() => setHints([]))
			.finally(() => {
				setLoading(false);
			});
	}, [story.id, story.assetId, story.sessionId, context]);

	return (
		<div className={styles.hints}>
			{loading ? (
				<IconLoader className={styles.hints__spinner} size={30} />
			) : hints && hints.length > 0 ? (
				<HintList hints={hints} />
			) : (
				<div className={styles.hints__unavailable}>
					{"No hints are available at the moment."}
				</div>
			)}
			<BackLink onClick={onClose} />
		</div>
	);
}

function HintList({ hints }) {
	return (
		<div className={styles.hints__list}>
			{hints.map((hint, i) => (
				<Hint key={i} hint={hint} index={i + 1} />
			))}
		</div>
	);
}

function Hint({ hint, index }) {
	const [visible, setVisible] = useState(false);
	const { text } = hint;

	return (
		<div className={styles.hint}>
			<div className={styles.hint__title}>{`Hint ${index}`}</div>
			{visible ? (
				text
			) : (
				<Button inline onClick={() => setVisible(true)}>
					{"Reveal"}
				</Button>
			)}
		</div>
	);
}

function LiveChat({ method, iframeSrc, onClose }) {
	if (method === "iframe") {
		return (
			<div className={styles.liveChat}>
				<iframe
					className={styles.liveChat__iframe}
					title="Live Chat"
					src={iframeSrc}
				/>
				<Button spaceTop onClick={onClose}>
					{"Close chat"}
				</Button>
			</div>
		);
	}
	return null;
}

function Button({ inline, spaceTop, className, children, loading, ...props }) {
	return (
		<button
			className={classnames(
				className,
				styles.button,
				spaceTop ? styles.spaceTop : null,
				inline ? styles.inline : null,
				loading ? styles.loading : null
			)}
			{...props}
		>
			{loading ? (
				<IconLoader className={styles.button__spinner} size="1em" />
			) : (
				children
			)}
		</button>
	);
}

function BackLink({ onClick }) {
	return (
		<div className={styles.backLink} onClick={onClick}>
			{"Back"}
		</div>
	);
}
