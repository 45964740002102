import styles from "./SigninCompletePage.module.css";

import { useEffect } from "react";

export default function SigninCompletePage() {
	useEffect(() => {
		if (window.opener) {
			window.opener.postMessage("signinComplete");
		}
		window.close();
	}, []); // eslint-disable-line

	return (
		<div className={styles.page}>
			<div className={styles.title}>
				{
					"Sign in completed successfully. You can safely close this window now."
				}
			</div>
		</div>
	);
}
