import styles from "./EndingPage.module.css";
import StoryFeedbackForm from "../components/StoryFeedbackForm";
import { pageTitleStory } from "../config";
import imgCrown from "../images/common/crown.svg";
import * as Backend from "../advStoreClient";

import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router-dom";
import Confetti from "react-confetti";
import { useState, useEffect } from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import classnames from "classnames";
import { Loader as IconLoader } from "lucide-react";

export default function EndingPage({ story, endingId }) {
    const [animateOut, setAnimateOut] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState(false);

    if (!story) {
        return <Redirect to="/" />;
    }

    const ending = story.endings[endingId];
    if (!ending) {
        return <Redirect to="/" />;
    }

    const { title, text, nextInteraction, nextText } = ending;
    const type = ending.type || "success";
    const success = type === "success";
    const sessionId = new URL(window.location.href).searchParams.get("sid");

    function handleRedirect(url) {
        setAnimateOut(true);
        setRedirectUrl(url);
        setTimeout(() => {
            window.location.href = url;
        }, 5000);
    }

    return (
        <div className={styles.page}>
            <Helmet>
                <title>{pageTitleStory(story.title)}</title>
            </Helmet>
            <MainImage type={type} title={title} animateOut={animateOut} />
            {title ? <div className={styles.title}>{title}</div> : null}
            {text ? <div className={styles.text}>{text}</div> : null}
            {success ? (
                <StoryFeedbackForm
                    story={story}
                    endingId={endingId}
                    sessionId={sessionId}
                />
            ) : null}
            {redirectUrl ? (
                <a className={styles.redirectMessage} href={redirectUrl}>
                    {
                        "You are being redirected, please click here if your browser does not redirect you."
                    }
                </a>
            ) : nextInteraction && nextText ? (
                <InteractionButton
                    story={story}
                    text={nextText}
                    interaction={nextInteraction}
                    sessionId={sessionId}
                    endingId={endingId}
                    onRedirect={handleRedirect}
                />
            ) : null}
            {success ? <FullScreenConfetti /> : null}
        </div>
    );
}

function MainImage({ type, title, animateOut }) {
    switch (type) {
        case "success":
            return <img src={imgCrown} className={styles.image} alt={title} />;
        case "restart":
            return <RestartClock animate={animateOut} />;
        default:
            return null;
    }
}

function InteractionButton({
    story,
    interaction,
    text,
    sessionId,
    endingId,
    onRedirect,
}) {
    const [loading, setLoading] = useState(false);

    function handleClick() {
        setLoading(true);
        Backend.storyInteractEnding({
            storyId: story.id,
            sessionId,
            endingId,
            interactionType: interaction,
        })
            .then(({ data }) => {
                if (data.url) {
                    onRedirect(data.url);
                }
            })
            .catch(() => {
                // TODO: error
                setLoading(false);
            });
    }

    return (
        <div
            className={styles.ctaButton}
            onClick={loading ? null : handleClick}
        >
            {loading ? (
                <IconLoader className={styles.ctaButton__spinner} />
            ) : (
                text
            )}
        </div>
    );
}

function FullScreenConfetti() {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function resizeHandler(evt) {
            setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    const [width, height] = windowSize;

    return (
        <Confetti
            style={{ pointerEvents: "none" }}
            width={width}
            height={height}
            numberOfPieces={200}
            recycle={false}
            gravity={0.15}
        />
    );
}

function getWindowSize() {
    return [document.body.clientWidth, document.body.clientHeight];
}

function RestartClock({ animate }) {
    const [value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = animate
            ? setInterval(
                  () => setValue((date) => substractMinutes(date, 60)),
                  1000
              )
            : setInterval(() => setValue(new Date()), 1000);
        return () => clearInterval(interval);
    }, [animate]);

    return (
        <Clock
            className={classnames(
                styles.clock,
                animate ? styles.animate : null
            )}
            value={value}
            renderHourMarks={false}
            renderMinuteMarks={false}
            renderSecondHand={false}
            hourHandWidth={3}
            minuteHandWidth={3}
            minuteHandOppositeLength={0}
            hourHandOppositeLength={0}
        />
    );
}

function substractMinutes(date, minutes) {
    return new Date(date.getTime() - 1000 * 60 * minutes);
}
