import styles from "./Anchor.module.css";
import Story from "./common/Story";
import { Tile } from "./common/storyCommons";
import ResendMessage from "./common/ResendMessage";
import VideoCall from "./common/VideoCall";

export default function Anchor({ story }) {
	return (
		<Story
			story={story}
			transitionAnimation="animation2"
			introBullets={INTRO_BULLETS}
			TilesComponent={TilesComponent}
			className={styles.story}
		/>
	);
}

function TilesComponent({
	firstStep,
	currentStep,
	onSetStep,
	onStartStory,
	onInteract,
}) {
	function handleVideoCallEnded() {
		onStartStory();
		onSetStep(firstStep + 1);
	}

	function handleResendMessage() {
		onInteract("resend_email");
	}

	return (
		<>
			<Tile stepNumber={firstStep} fullWidth currentStep={currentStep}>
				<VideoCall
					name="Detective Burke"
					videoUrl={{
						file: "https://advusercontent.com/anchor/videos/intro1.mp4",
						hls: "https://videodelivery.net/76d361042265f8d2bed9c1a4afdb5fd9/manifest/video.m3u8",
						dash: "https://videodelivery.net/76d361042265f8d2bed9c1a4afdb5fd9/manifest/video.mpd",
					}}
					aspectRatio={9 / 16}
					onEnded={handleVideoCallEnded}
				/>
			</Tile>
			<Tile stepNumber={firstStep + 1} currentStep={currentStep}>
				<PostVideoText onResend={handleResendMessage} />
			</Tile>
		</>
	);
}

const INTRO_BULLETS = [
	{
		icon: "globe",
		content:
			"Prepare to revel in an immersive and interactive adventure that all takes place in the real world!",
	},
	{
		icon: "mobile",
		content:
			"To fully rejoice in the adventure, you will be best served by procuring a phone capable of sending and receiving calls and emails.",
	},
	{
		icon: "headphones-1",
		content:
			"To interact competently with our cast of characters, we recommend wearing headphones upon your ears.",
	},
	{
		icon: "megaphone",
		content:
			"Good players, this adventure is still a work in progress, and your feedback is most critical to improving it. Please report to us anything unclear or broken and it will be addressed!",
	},
];

function PostVideoText({ onResend }) {
	return (
		<div className={styles.intro}>
			<p>{"Help Detective Burke solve the murder case."}</p>
			<p>
				<i>{"Don't forget to check your email."}</i>
			</p>
			<ResendMessage
				text="Didn't receive an email from the detective?"
				onResend={onResend}
			/>
		</div>
	);
}
