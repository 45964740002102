import styles from "./DisallowedPermsNotice.module.css";
import Button from "../../forms/Button";

import { Link } from "react-router-dom";

const PERM_TEXTS = {
	sms: "Incoming text messages",
	calls: "Incoming phone calls",
	emails: "Incoming emails",
};

export default function DisallowedPermsNotice({ disallowedPerms }) {
	return (
		<div className={styles.notice}>
			<p>
				{
					"To play this adventure, please turn on the following permissions in your user account page:"
				}
			</p>
			<ul className={styles.perms}>
				{disallowedPerms.map((perm) => (
					<li key={perm} className={styles.perms__item}>
						{PERM_TEXTS[perm]}
					</li>
				))}
			</ul>
			<Link to="/account">
				<Button>{"Go to account page"}</Button>
			</Link>
		</div>
	);
}
