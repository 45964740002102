import styles from "./Footer.module.css";
import { Container } from "@material-ui/core";

export default function Footer() {
	return (
		<footer className={styles.footer}>
			<Container maxWidth="md">
				<a
					className={styles.footer__link}
					href="https://faintlines.com/privacy/"
				>
					{"Privacy"}
				</a>
				<a
					className={styles.footer__link}
					href="https://faintlines.com/termsofuse/"
				>
					{"Terms of Use"}
				</a>
			</Container>
		</footer>
	);
}
